import { FetchBaseQueryError, createApi, fetchBaseQuery, skipToken } from '@reduxjs/toolkit/query/react'

export interface surveyCreateMatch {
    id: number,
    type: string
}

export const surveyApi = createApi({
    reducerPath: 'surveyApi',
    baseQuery: fetchBaseQuery(),
    tagTypes: ['Survey'],
    endpoints: (builder) => ({
        //gest profile  by iam_is inserts if no matching profile found        
        getSurvey: builder.query<any, surveyCreateMatch>({
            query: (args) => {
                const id = args.id
                const qry = `/api/surveys/?id=${id}&type=${args.type}`
                return qry
            },
            providesTags: (result, response, updated) => {
                return [{ type: 'Survey', id: updated.id }]
            }
        }),

        patchSurvey: builder.mutation<any, { id: number, type: string, stepsCompleted: number, surveyDTO: any }>({
            query: ({ id, type, stepsCompleted, surveyDTO: survey2Update }) => ({
                url: `/api/surveys?id=${id}&type=${type}&stepsCompleted=${stepsCompleted}`,
                method: 'PATCH',
                body: survey2Update,
            }),
            invalidatesTags: (result, response, updated) => [{
                type: 'Survey',
                id: updated.id
            }]
        }),

        updateSurvey: builder.mutation<any, { id: number, type: string, surveyDTO: any }>({
            query: ({ id, type, surveyDTO: survey2Update }) => ({
                url: `/api/surveys?id=${id}&type=${type}`,
                method: 'PUT',
                body: survey2Update,
            }),
            invalidatesTags: (result, response, updated) => [{
                type: 'Survey',
                id: updated.id
            }]
        }),
        
        deleteUserSurveys: builder.mutation<void, number>({
            query: (id: number) => {
                //console.log('Deleting user surveys', id)
                return ({
                    url: `/api/survey?id=${id}`,
                    method: 'DELETE',
                })
            },
            //invalidatesTags: (result, response, updated) => [{ type: 'Profile', id: updated }]
        }),
    }),
})


export const { useGetSurveyQuery, useUpdateSurveyMutation, usePatchSurveyMutation, useDeleteUserSurveysMutation } = surveyApi

