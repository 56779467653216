import { Box, Divider, Typography } from "@mui/material"
import React, { createContext, ReactNode } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { AnalyticUsersClickedType, setPublicPinClicked, PublicPinClicked, GetIdFromPublicPinClicked } from "../../features/dataProcessingSlice";
import { AILoading } from "./AILoading";
import { AccountPromotion } from '../iam/AccountPromotion';
//import { showProfile } from "../../features/dialogSlice";
import { AnalyticUsers } from "./AnalyticUsers";
import { AIProfile } from "./AIProfile";
import { useAuthProfile } from "../../app/hooks";
import { Stack, IconButton, Container, Skeleton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MessageProfile } from '../MessageProfile';
import { useAIProfileSummaryQuery } from "../../features/aiAnalytics";
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetProfileQuery } from "../../features/profilesSlice";
import { useAIProfileForPinClicked } from "./AIhook";
import Button from '@mui/material/Button';
import BarChartIcon from '@mui/icons-material/BarChart';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const defaulfAISummaryContextValue : AISummaryComponentProps = { bottomSheetRef:null, showAccountPromotion: false, showViewUserStoryAnalytics: true, showMessageButton: true, chatMode: false, pinToUse: false}
export const AISummaryContext = createContext(defaulfAISummaryContextValue);

export interface AISummaryComponentProps {
    children?: ReactNode;
    bottomSheetRef: any,
    showAccountPromotion: boolean,
    showViewUserStoryAnalytics?: boolean,
    showMessageButton?: boolean
    chatMode?: boolean,
    pinToUse: PublicPinClicked
    //isAuthProfileLoading: boolean
}
export const AISummary: React.FC<AISummaryComponentProps> = (props) => {
    const { bottomSheetRef, showViewUserStoryAnalytics = true, showMessageButton = true, chatMode = false, children , pinToUse} = props
    //const { profile, isAuthProfileLoading } = props;
    const dispatch = useDispatch<any>()
    //const publicPinClickedAIState = useSelector((state: any) => state.dataProcessing.publicPinClicked)
    //const AIState_publicPinClicked = useTypedSelector((state: RootState) => state.dataProcessing.publicPinClicked)
    const pinId = GetIdFromPublicPinClicked(pinToUse)??skipToken
   
    const { aiSummary: AIProfileSummary, authProfile: authProfile, profile: profileToUse, isLoading, isSuccess, isError, error } = useAIProfileForPinClicked(pinId)
    const analyticUsersClickedAIState: AnalyticUsersClickedType = useSelector((state: any) => state.dataProcessing.analyticUsersClicked)

    const chatState = useSelector((state: any) => state.dialog);

    function closeProfile() {
        dispatch(setPublicPinClicked(false))
    }

    if (isError) {
        const errMsg = 'status' in error ? `Error: ${error.status}` : 'error';
        return <div>{errMsg}</div>;
    }


    //refactor(loggedInUserProfile.iam_id !== AIState.publicPinClicked && !AIState.isSuccessProfile)) {
    if (isLoading)
        //console.log(AIState.publicPinProfile)
        return (         
            <AISummaryContext.Provider value={props} > 
            <Container>
                <Stack sx={{ width: '100%', pt:4, height: { xs: 'auto', sm: 'auto', md: 'calc(100vh - 80px)' }  }} alignItems="center" direction="column" gap={1}>
                    <Button sx={{ color: '#656EFF', borderRadius:8, fontSize:14 }} onClick={closeProfile} variant="outlined" disableElevation startIcon={<ArrowBackIcon />}>
                        Home
                    </Button>
                    <Skeleton animation="wave" variant="rectangular" width='100%' height={50}/>
                    <Skeleton animation="wave" variant="rectangular" width='100%' height={250}/>
                    <Skeleton animation="wave" variant="rectangular" width='100%' height={250}/>
                    <Skeleton animation="wave" variant="rectangular" width='100%' height={300}/>
                </Stack>
            </Container>  
            </AISummaryContext.Provider>
        )

    // if( AIState.errorGenerateAISummary ) still show this


    if (!profileToUse)
        return (<>{children ?<> { children }</> : <>No profile available for AI processing</>}</>)


    let aiSummaryRecord: any = undefined
    if (typeof analyticUsersClickedAIState !== "boolean") {
        if ((AIProfileSummary.ai_summary.length - 1) < analyticUsersClickedAIState.analyticsArrayIndex) {
            const err = `Analytic record Index out of Bound:  ${AIProfileSummary.ai_summary.length} vs ${analyticUsersClickedAIState.analyticsArrayIndex}`
            //console.log(err)
            return <>{err}</>
        }
        aiSummaryRecord = AIProfileSummary.ai_summary[analyticUsersClickedAIState.analyticsArrayIndex]
        if (aiSummaryRecord.var !== analyticUsersClickedAIState.analyticsVariable) {
            const err = `Analytic record Mismatched:  ${aiSummaryRecord.var} vs ${analyticUsersClickedAIState.analyticsVariable}`
            //console.log(err)
            return <>{err}</>
        }
        //analyticUsers = analyticUsersClickedAIState.analyticType === "numeric" ? aiSummaryRecord.analytics.users : (analyticUsersClickedAIState.analyticType === "text" ? aiSummaryRecord.analytics : undefined)

    }
    if (isSuccess)
        return (
            <Box sx={ chatMode ? 
                { 
                    display: { xs: chatState.showChatProfile, sm: chatState.showChatProfile, md: 'flex' }, 
                    overflow: 'auto', 
                    width: '100%', 
                    height: '100%' 
                }
                :
                { 
                    overflow: 'auto', 
                    width: '100%', 
                    height: { xs: 'auto', sm: 'auto', md: 'calc(100vh - 80px)' } 
                }
            }>
            <AISummaryContext.Provider value={props} >
                {analyticUsersClickedAIState !== false ? <AnalyticUsers aiSummaryRecord={aiSummaryRecord} /> :
                    <AIProfile bottomSheetRef={bottomSheetRef} profileToShow={profileToUse} AIProfileSummary={AIProfileSummary} showMessageButton={showMessageButton} showViewUserStoryAnalytics={showViewUserStoryAnalytics} chatMode={chatMode} />}
            </AISummaryContext.Provider>
            </Box>
        )
    return null
}


