import {
  DirectionsRenderer,
  DirectionsService,
  Libraries,
  GoogleMap,
} from '@react-google-maps/api'

import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AutosuggestHighlightParse from "autosuggest-highlight/parse";
import { debounce } from '@mui/material/utils';
import { Chip } from '@mui/material';
import { CircularProgressCentered } from '../sharedcomponents';

// This key was created specifically for the demo in mui.com.
// You need to create a new one for your application.
const GOOGLE_MAPS_API_KEY = 'AIzaSyC3aviU6KHXAjoSnxcw6qbOhjnFctbxPkE';
export const goggleMapsApiKey = "AIzaSyDvqapzpG_NMGMUv9Nj9IJnLNasbt_XzR8"

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
export interface PlaceType {
  description: string;
  place_id?: string,
  structured_formatting?: StructuredFormatting;
}

interface LocationsAutocompleteProps {
  //defaultInputValue?: string;
  value: PlaceType;
  onChange: (value: PlaceType | null) => void;
  countryCode?: string;
  [key: string]: any; // To allow for additional props
}

//export const LocationsAutocomlete: React.FC<LocationsAutocompleteProps> = (props) => {
export const LocationsAutocomplete = React.forwardRef<
  any, // The type of the ref (you can replace `any` with a specific type if needed)
  LocationsAutocompleteProps
>((props, ref) => {
  const { value, onChange, countryCode, helperText, label, error, defaultValue, ...restProps } = props

  const [shouldOpen, setShouldOpen] = React.useState(false);
  //const [value, setValue] = React.useState<PlaceType[] | undefined>(undefined);
  const [inputChanged, setInputChanged] = React.useState(false);
  const [inputValue, setInputValue] = React.useState(value?.description);
  const [options, setOptions] = React.useState<readonly PlaceType[]>(value ?[{...value, structured_formatting:{main_text:value.description, secondary_text:'', main_text_matched_substrings: [{length: value.description.length,  offset: 0,}], } }]:[]);
  const [optionsState, setOptionsState] = React.useState<{ isUnInitialized: boolean, isLoadng: boolean, isSuccess: boolean }>({ isUnInitialized: true, isLoadng: false, isSuccess: false });
  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    // if (!document.querySelector('#google-maps')) {
    //   loadScript(
    //     `https://maps.googleapis.com/maps/api/js?key=${goggleMapsApiKey}&libraries=places`,
    //     document.querySelector('head'),
    //     'google-maps',
    //   );
    // }

    loaded.current = true;
  }
  React.useImperativeHandle(ref, () => ({
    focus: () => {
      if (inputRef.current)
        inputRef.current?.focus();
    },
    resetAndFocus() {
      setOptionsState({ isUnInitialized: true, isLoadng: false, isSuccess: false })
      setOptions([]); // Clear the selected value
      setInputValue(''); // Reset the input value      
      onChange(null); // Notify the parent that the value has been reset
      if (inputRef.current) {
        inputRef.current.focus(); // Focus the input element
      }
    }
  }));

  //const readOnly =  value.length > 0 && !inputClear
  React.useEffect(() => {
    if (!value && inputRef.current) {
      inputRef.current.blur();
      inputRef.current.disabled = true; // Temporarily disable the input
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.disabled = false; // Re-enable the input
          const touchEvent = new Event('touchstart', { bubbles: true, cancelable: true });
          inputRef.current.dispatchEvent(touchEvent); // Simulate a touch event
          inputRef.current.focus();
        }
      }, 300); // Small delay to ensure proper focus
    }
  }, [value]);

  React.useEffect(() => {
    const inputFoundInOptions = options.find(o => o?.description === inputValue)
    const shouldOpenConst = !inputFoundInOptions && inputValue !== value?.description && optionsState.isSuccess && !!options.length
    setShouldOpen(shouldOpenConst);
  }, [value?.description, inputValue, optionsState.isSuccess, options]);

  const fetch = React.useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: readonly PlaceType[]) => void,
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            {
              ...request, types: ['(regions)'], // ['geocode'], 
              componentRestrictions: { country: countryCode }, // Optionally restrict to a specific country (e.g., US)
              strictBounds: false,
            },
            callback,
          );
        },
        400,
      ),
    [countryCode],
  );

  React.useEffect(() => {
    if (!inputChanged) {
      //setOptions([value]);
      return
    }
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions([])//value ? value : []);
      return undefined;
    }
    setOptions([])
    setOptionsState({ ...optionsState, isLoadng: true, isSuccess: false, isUnInitialized: false })
    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];
        const resultsNotAlreadPicked = results//?.filter(res => !(value?.description === res.description))
        if (results) {
          newOptions = [...resultsNotAlreadPicked ?? []]// [...newOptions, ...results];
        }
        console.log("newOptions", newOptions)
        setOptions(newOptions);
        setOptionsState({ ...optionsState, isLoadng: false, isSuccess: true })
      }
      setOptionsState({ ...optionsState, isLoadng: false, isSuccess: true })
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, inputChanged, fetch]);
  const handleOpen = () => {
    setShouldOpen(true);
    if( options.length <= 1){
    //setInputChanged( true)
    }
    //setInputValue(inputValue + ' NY ')
  };

  const handleClose = () => {
    setShouldOpen(false);
  };
  //const shouldOpen = inputValue !== value?.description && options.length > 0;
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  return (
    <>{optionsState.isLoadng && <CircularProgressCentered />}
      <Autocomplete
        {...restProps}
        fullWidth
        id="google-map-demo"
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option
          }
          return option?.description ?? ''
        }}
        isOptionEqualToValue={(option: PlaceType, value: PlaceType) => {
          //if( !value)            return option.label == 'United States'
          return value?.description === option?.description
        }}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        //filterSelectedOptions
        //defaultValue={defaultValue}
        value={value ?? null}
        open={!!shouldOpen}
        onOpen={handleOpen} // Open dropdown on clicking the down arrow or focusing
        onClose={handleClose} // Close dropdown when needed
        onChange={(event: any, newValue: PlaceType | null) => {
          //if (newValue) {
          onChange(newValue ?? null)

          //}
        }}
        inputValue={inputValue ?? ''}
        onInputChange={(event, newInputValue) => {
          //newInputValue = newInputValue.trim()
          //if (newInputValue !== defaultValue?.description && newInputValue !== value?.description ) {
          const inputFoundInOptions = options.find(o => o?.description === newInputValue)
          if (event && (event.type === 'change' || event.type === 'click'  || event.type === "keydown")) {
            setInputValue(newInputValue);  // Update input value normally
            //setShouldOpen(!inputFoundInOptions && newInputValue !== value?.description && optionsState.isSuccess && !!options.length );
          }
          //} 
          if (value && newInputValue !== defaultValue?.description && newInputValue !== value?.description) {
            onChange(null)
            //setOptions([])
          }
          //setInputChanged( !options.find( o=> o?.description === newInputValue) && newInputValue !== defaultValue?.description && newInputValue !== value?.description )
          setInputChanged(!inputFoundInOptions && newInputValue !== defaultValue?.description && newInputValue !== value?.description)
        }}
        // renderTags={(value, getTagProps) =>
        //   value.map((option, index) => (
        //     <Chip variant="outlined" label={option.description} {...getTagProps({ index })} />
        //   ))
        // }
        onBlur={() => {
          if (inputChanged && value && value.description !== inputValue) {
            // Prevent reverting to the last selected value if the user is typing
            onChange(null) // Reset the selection if input doesn't match the selected value
          }
        }}
        renderInput={(params) => {
          const locationError = optionsState.isSuccess && options.length === 0 && inputValue && inputValue !== value?.description ? true : undefined
          const locationHelperText = (locationError && inputValue && optionsState.isSuccess && options.length === 0) ? `No matching Location found for ${inputValue}` : helperText ? helperText.trim() : ''
          //console.log(optionsState.isSuccess)
          return (
            <TextField {...params} type="text" value={inputValue ?? ''} inputRef={inputRef} InputProps={{ ...params.InputProps, autoComplete: 'new-password', }}
              variant="outlined"
              //label={value.length === 0 ? "Please type in zip/postal code or city/town you live in" : "Click X to choose a different location"}
              label={label}
              error={locationError || !!error}
              helperText={locationHelperText}
              fullWidth />
          )
        }}
        noOptionsText=""
        renderOption={(props, option) => {
          const matches =
            option?.structured_formatting?.main_text_matched_substrings || [];

          const parts = AutosuggestHighlightParse(
            option?.structured_formatting?.main_text ?? '',
            matches.map((match: any) => [match.offset, match.offset + match.length]),
          );

          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: 'flex', width: 44 }}>
                  <LocationOnIcon sx={{ color: 'text.secondary' }} />
                </Grid>
                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                  {parts.map((part: any, index: number) => (
                    <Box
                      key={index}
                      component="span"
                      sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                    >
                      {part.text}
                    </Box>
                  ))}
                  <Typography variant="body2" color="text.secondary">
                    {option?.structured_formatting?.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    </>);
})
