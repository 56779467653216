import React, { useCallback, useEffect, useState } from 'react';
import { Alert, useMediaQuery, useTheme } from '@mui/material';
//import { ProfileFormData, QuestionsForm, ProfileQuestionsFormProps } from '../wizard/QuestionsForm'
//import { HeaderContent } from '../../app/sharedcomponents';
import { useDispatch, useSelector } from 'react-redux';
//import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { ShowDialogInterface, showProfile } from "../../features/dialogSlice";
//import Slide from '@mui/material/Slide';
//import { TransitionProps } from '@mui/material/transitions'
import { profileApi, useUpdateProfileMutation } from '../../features/profilesSlice';
import { getSearchStateFromPayload, getSearchByTerm } from '../../features/searchSlice'
//import { CircularProgressCentered } from '../../app/sharedcomponents'
import { ProfileFormData, StepType } from '../wizard/QuestionsForm'
//import { YesNo } from '../wizard/YesNo';
//import { store } from '../../app/store';
import { WizardDialog, UpdateStepArgsType } from '../wizard/WizardDialog';
//import { UserType } from './UserType'
import { AnythingToShare } from './AnythingToShare'
import { Location } from './Location'
//import { Communicate } from './Communicate';
import { ThankYou } from '../survey/ThankYou';
//import { useNavigate } from 'react-router-dom';
import { /*ReplaceStep,*/ ReplaceStepBySubTitle } from '../survey/SurveyWizard';

//import { generateAISummary, getProfileFromID, setCenter } from "../../features/dataProcessingSlice"
import { ReviewYourPin } from './ReviewYourPin';
//import { CircleNotifications } from '@mui/icons-material';
import { CircularProgressCentered } from '../../app/sharedcomponents';
import { aiAnalyticsApi, useGenerateAISummaryMutation } from '../../features/aiAnalytics';
import { generateAISummaryThunk, setPublicPinClicked } from '../../features/dataProcessingSlice';
import { useAuthProfile } from '../../app/hooks';
import { printError } from '../../app/utils';


interface UpdateSate {
  isSuccess: boolean | undefined,
  step: number | undefined,
  data: any
  profileDTO: any
  args: UpdateStepArgsType | undefined
}


const profileSteps: StepType[] = [{
  screen: <Location />,
  title: "My Pin",
  subTitle: 'What is your zipcode?',
},
// {
//   screen: <UserType />,
//   title: "My Pin",
//   subTitle: 'Are you a Warrior, a Caregiver or a Medical professional?',
// },
{
  screen: <AnythingToShare />,
  title: "My Pin",
  subTitle: "My Story",
  //buttonTitle: "Finish",
}, //
{
  screen: <ReviewYourPin />,
  title: "My Pin",
  subTitle: "Review your pin",
  buttonTitle: "Finish",
}, //

{
  screen: <ThankYou shoowCheckMark={false} />, subTitle: "Thank you for adding your pin!",
  subTitle2: "Now that you have added your pin, you can find patients similar to you, learn from their stories, and connect with them if you choose.",
  buttonTitle: "OK",

  noProgressBar: true, noBackArrow: true,
  showBottomRight: true,
  autoSizeDialog: true
}

  // { screen: <Communicate />,
  //   title: "Communication",
  //   subTitle: 'Would you be open to communicating with other Multiple Myeloma Warriors, caregivers or medical professionals?',
  // },
  // { screen: <YesNo fieldName='receive_emails' />,
  //   title: "Communication",
  //   subTitle: 'Do you want to receive emails about things happening in our community?',
  // }
  //'Questions'
];


// interface MapDialogProps extends SurveyDialogProps {
//   UpdateStep: (formData: any, step: number, methods: any) => any
//   updatedEffect: (isUpdatedState: any, methods: any) => void
// }




interface ProfileQuestionsDialogProps //extends ProfileQuestionsFormProps {
{
  loggedInUserProfile: any
  //onClose: () => void;
}


export const ProfileWizard1: React.FC = () => {
  const showProfileState: ShowDialogInterface = useSelector((state: any) => state.dialog.showProfile)
  //  showProfileState.screenTitle?  profileSteps.findIndex( step => step.title === showProfileState.screenTitle)  : 0

  //screen: "Review your pin", singleScreen: true }

  const { authStatus, profile: loggedInUserProfile, isLoading: isLoadingLoggedInUserProfile, isSuccess: isSuccessLoggedInUserProfile, isError: isErrorLoggedInUserProfile, error: errorLoggedInUserProfile, userAttributesStateStatus } = useAuthProfile()
  const [lastFormLocatoin, setLastFormLocatoin] = useState({ zipCode: loggedInUserProfile.zipcode, country: loggedInUserProfile.country })
  const [updateStepArgs, setUpdateStepArgs] = useState<UpdateStepArgsType | undefined>(undefined);
  const [stepIndex, setStepIndex] = useState(showProfileState.step ?? 0);
  const [wizardSteps, setWizardSteps] = useState(profileSteps);
  const dispatch = useDispatch<any>();// useAppDispatch()
  const [isUpdated, setIsUpdated] = useState<UpdateSate>({ isSuccess: false, step: undefined, data: undefined, profileDTO: undefined, args: undefined });
  const [UpdateProfile, { isLoading: isUpdating, isSuccess, isError, error, data: updatedFormData/*, reset*/, originalArgs }] = useUpdateProfileMutation()
  const [generateAISummary,  /*{  isLoading: isLoadingGenerateAISummary, isSuccess: isSuccessGenerateAISummary, isError: isErrorGenerateAISummary, error: errorGenerateAISummary }*/] = useGenerateAISummaryMutation()
  //const navigate = useNavigate()
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


  const { formData, sumbitAattribute, stepIndexUpdate, methods } = updateStepArgs ?? {}

  useEffect(() => {
    var lastStep = stepIndex === wizardSteps.length - 1
    if (!sumbitAattribute && stepIndexUpdate?.steps[stepIndexUpdate.stepIndex].subTitle === "Review your pin") {
      if (Object.entries(methods?.formState?.dirtyFields ?? {}).length > 0) {
        const { status, ...noStatusProfileDTO } = isUpdated.profileDTO ?? {}
        const { location, ...noLocationData } = isUpdated.data ?? {}
        const profileDTOforUpdatae = { ...noLocationData, ...location, ...noLocationData, /*...noLocationFormData, ...locationFormData, */...noStatusProfileDTO, ...(location?.zipCode && location?.country) ? { isCompleted: true } : {} }
        UpdateProfile({
          id: loggedInUserProfile.id, profileDTO: { ...profileDTOforUpdatae }
        })
        //generateAISummary({ id: profile.id, story: formData.comment })
        if (methods?.formState?.dirtyFields?.comment) {
          dispatch(generateAISummaryThunk({ id: loggedInUserProfile.id, story: formData?.comment }))
        }
        return //conitinue in useEffect
      }
    }
    switch (sumbitAattribute) {
      case 'edit-story':
        setStepIndex(1)
        return
        break
      case 'edit-personal-info':
        setStepIndex(0)
        return
        break
      default:
        if (!lastStep) {
          //setStepIndex(stepIndex + 1)
        }
    }

    //no updates
    if (isUpdated?.step !== undefined) {
      if (lastStep) {
        dispatch(showProfile(undefined))//close wizard 
      } else {
        setStepIndex(stepIndex + 1)
      }
    }
  }, [isUpdated])//, updateStepArgs])



  //const lastStep = updateStepArgs?.stepIndexUpdate?.stepIndex === wizardSteps.length - 1
  useEffect(() => {
    if (isSuccess && loggedInUserProfile.id && updateStepArgs) {
      const lastStep = stepIndex === wizardSteps.length - 1
      // no need since mutation will invalidate dispatch(aiAnalyticsApi.util.invalidateTags([{ type: 'AiProfile', id: updatedFormData.updatedUser.id }]))        
      console.log('ProfileWizard1 isSuccess', isSuccess)
      dispatch(profileApi.util.invalidateTags([{ type: 'Profile', id: loggedInUserProfile.id }]));
      //show propfile 
      dispatch(setPublicPinClicked(loggedInUserProfile.id))
      if (!lastStep) {
        setStepIndex((prevStepIndex) => prevStepIndex + 1)
        //IncrementStepIndex()
      }

      if (lastStep && !updateStepArgs?.sumbitAattribute) {
        /*if (wizardSteps[stepIndexUpdate.stepIndex].subTitle === "Thank you for adding your pin!") {
          navigate("/Resources")
        }*/
        dispatch(showProfile(undefined))//close wizard       
      }
    }
  }, [isSuccess, dispatch, loggedInUserProfile.id, updateStepArgs, updateStepArgs?.sumbitAattribute])

  useEffect(() => {
    if (isError) {
      console.log('Profile Wizard Update Error', error)
      if (printError(error).toLocaleLowerCase().includes("name_public")) {
        methods.setError('name_public', { type: 'validate', message: `Username ${originalArgs?.profileDTO?.name_public} is already taken` });
      } else {
        methods.setError('root.serverError', { type: '400', message: "Oops... Could not update profile, please try later" });
      }
    }
  }, [isError])



  if (!showProfileState)
    return null

  const UpdateStep: (args: UpdateStepArgsType) => any = async (args) => {
    setUpdateStepArgs(args)
    const { formData, sumbitAattribute, stepIndexUpdate, methods } = args
    const { dirtyFields } = methods.formState
    const lastStep = stepIndexUpdate.stepIndex === wizardSteps.length - 1


    if (!sumbitAattribute && stepIndexUpdate?.steps[stepIndexUpdate.stepIndex].subTitle === "Review your pin") {
      if (loggedInUserProfile?.is_completed || !(formData?.location.country && formData?.location.zipCode)) {
        setWizardSteps((oldSteps: StepType[]) => {
          const { isNew, steps } = ReplaceStepBySubTitle(oldSteps, undefined, "Thank you for adding your pin!")
          return isNew ? steps : oldSteps
        })
      }
    }


    if (Object.entries(dirtyFields).length > 0) {
      if (dirtyFields.location && (lastFormLocatoin.country !== formData.location.country || lastFormLocatoin.zipCode !== formData.location.zipCode)) {
        const ret = await dispatch(getSearchByTerm(`${formData.location.zipCode} ${formData.location.country}`))
        //dispatch(profileApi.util.invalidateTags(['ProfilePinData'])) // to update distances
        switch (ret.type) {
          case 'search/getSearchByTerm/fulfilled':
            setLastFormLocatoin(formData.location)
            const profileDTO = getSearchStateFromPayload(ret.payload)
            if (profileDTO.zipCode) {
              profileDTO.formattedsearchterm = profileDTO.formattedsearchterm.replace(profileDTO.zipCode, '').replace('  ', ' ').replace(' ,', ',').trim()
              //formData.location.zipCode = formData.location.zipCode.replace(profileDTO.zipCode, '').replace('  ', ' ').replace(' ,', ',').trim()
            }
            profileDTO.zipCode = formData.location.zipCode //could contain city, town, etc

            setIsUpdated({ ...isUpdated, isSuccess: true, step: stepIndexUpdate.stepIndex, data: formData, profileDTO: profileDTO })
            break;
          default:
            //methods.setError('submit', { type: 'manual', message: "Error finding location on map" });
            methods.setError('root.serverError', { type: '400', message: "Error finding location on map" });
            return false;
        }
      }
      else {
        setIsUpdated({ ...isUpdated, isSuccess: true, step: stepIndexUpdate.stepIndex, data: { ...isUpdated.data, ...formData } })
      }
      //setStepIndex(stepIndex + 1)
      return true
    }



    // switch (sumbitAattribute) {
    //   case 'edit-story':
    //     //setStepIndex(1)
    //     break
    //   case 'edit-personal-info':
    //     setStepIndex(0)
    //     break
    //   default:
    //     if (!lastStep) {
    //       //setStepIndex(stepIndex + 1)
    //     }
    setIsUpdated({ ...isUpdated, step: stepIndexUpdate.stepIndex })

    //}


    return true;
  }


  const handleBack = () => {
    if (stepIndex > 0) {
      setStepIndex(stepIndex - 1); // Go back a step
    } else {
      //onClose(); // Close the dialog if already at the initial step
      //onCloseLocal()
      dispatch(showProfile(undefined))
    }
  };



  //const isProfileForced = true
  const { zipCode, country, ...profileWithoutLocation } = loggedInUserProfile;
  const profileWithLocation: ProfileFormData = { ...profileWithoutLocation, location: { zipCode: loggedInUserProfile.zipcode, country: loggedInUserProfile.country } }

  const defaultValues = { location: profileWithLocation?.location?.country ? profileWithLocation?.location : { ...profileWithLocation?.location ?? { zipCode: null }, country: 'United States' }, }
  return (<>
    {isUpdating && <CircularProgressCentered />}


    <WizardDialog
      sx={{
        width: isSmallScreen ? '90%' : '90%',
        maxHeight: isSmallScreen ? '76vh' : '76vh', // Remove maximum height on small screens, set to 600px on larger screens
        borderRadius: '12px', padding: '12px',
      }}
      isLoading={isLoadingLoggedInUserProfile}
      isUpdating={isUpdating}
      steps={wizardSteps}
      open={showProfileState ? true : false}
      onClose={() => {
        setStepIndex(0)
        dispatch(showProfile(undefined))
      }}
      defaultFormDataformData={defaultValues}
      formData={profileWithLocation}
      wizardData={showProfileState}
      updatedData={isUpdated}
      handleBack={handleBack}
      updateStep={UpdateStep}
      stepIndex={stepIndex} />
  </>
  )

}

export const ProfileWizard = React.memo(ProfileWizard1);  // useEffect(() => {
//   if ((loggedInUserProfile?.is_completed && stepIndex === 0 )) {
//     if (stepIndex === 0 || showProfileState.singleScreen) { //no need tocan set and check isForced here
//       setWizardSteps((oldSteps: StepType[]) => {
//         const { isNew, steps } = ReplaceStepBySubTitle(oldSteps, undefined, "Thank you for adding your pin!")
//         return isNew ? steps : oldSteps
//       })
//     }
//   }
//     // if (showProfileState?.isForcedFromSurvey) {
//     //   if (!loggedInUserProfile.is_completed && loggedInUserProfile.type) { //can set and check isForced here
//     //     setWizardSteps((oldSteps) => {
//     //       const { isNew, steps } = ReplaceStepBySubTitle(oldSteps, undefined, 'Are you a Patient, a Caregiver or a Healthcare professional?')
//     //       return isNew ? steps : oldSteps
//     //     })
//     //   }
//     // }

// }, [loggedInUserProfile?.is_completed, stepIndex])//, loggedInUserProfile.type, , wizardSteps*/])
