import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { AccountPromotion } from './iam/AccountPromotion';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { CareCenterSummary } from './carecenter/CareCenterSummary'
import { PatientSummary } from './patient/PatientSummary'
import { Stack } from '@mui/material';
import { useAuthenticator } from "@aws-amplify/ui-react";
import { CompleteProfileChip } from './profile/CompleteProfileChip'
import Container from '@mui/material/Container';
//import { ToDoList } from './ToDoList';
//import LinearProgress from '@mui/material/LinearProgress';

//import { ReactComponent as AISVG } from '../assets/ai_star.svg';
//import { ReactComponent as MMWarriorSVG } from '../assets/label_mmwarrior.svg';
//import { ReactComponent as CaregiverSVG } from '../assets/label_caregiver.svg';
import { useAuthProfile } from "../app/hooks"
import { showProfile } from "../features/dialogSlice";
import Button from '@mui/material/Button';
//import Avatar from '@mui/material/Avatar';
//import CloseIcon from '@mui/icons-material/Close';
//import IconButton from '@mui/material/IconButton';
//import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
//import Link from '@mui/material/Link';
//import { Patients } from './patient/Patients';
import { getSearchByCoordinates } from "../features/searchSlice";
//import { MessageProfile } from './MessageProfile'
import { AISummary } from "./AI/AISummary"
import { /*getProfileFromID,*/ LeftSideComponentWithOptions, setCenter, setPublicPinClicked } from "../features/dataProcessingSlice"
import { activateMarker } from '../features/activeMarkers';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForward';
import AddIcon from '@mui/icons-material/Add';
import { CircularProgressCentered } from '../app/sharedcomponents';
import { InitialDataProcessingState } from '../features/dataProcessingSlice'
//import { RootState } from '@reduxjs/toolkit/query';
import { RootState, useTypedSelector } from '../app/store';
//import { AnalyticsChannelSummary, ChatUserNotFound } from './messaging/ChatCardMM';
//import {RoootState} from '../App/store'
import { ResourceMini } from './resources/ResourceMini';
import { NavMini } from './navbar/NavMini';
import { /*useGetProfilesForPointQuery,*/ useGetProfilesPinDataForDefaultPointQuery } from '../features/profilesSlice';
import { useCreateOrJoinOpenChatMutation } from '../features/ChatSlice';
import { OpenChannelMain } from './messaging/OpenChannelMain';

import { StorySearchSummary } from './search/StorySearchSummary'
import PlaceIcon from '@mui/icons-material/Place';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom";
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import Dialog from '@mui/material/Dialog';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { selectLastgetSearchByStoryyStatusArgsAndData } from '../App';

export const GetAnnouncementsChannelUrlForEnvironment: () => { channelName: string, channelUrl: string} = ()=>{
  //return 'Patient_Connect_Announcements'
  return { channelName: 'Patient Connect Announcements', channelUrl: 'Patient_Connect_Announcements'}
  // const environmentNODE_ENV: string = process.env.NODE_ENV || 'development';
  // const environmentREACT_APP_ENV: string = process.env.REACT_APP_ENV || 'test'
  // const url = environmentNODE_ENV !== 'development' && environmentREACT_APP_ENV !== 'test'? 'prod_announcements':'test_announcements'
  // return url
}

export function LeftDrawer(bottomSheetRef: any) {
  const dispatch = useDispatch<any>();
  const { authStatus, profile: loggedInProfile, isLoading: isAuthProfileLoading, isSuccess, isError, error, userAttributesStateStatus, messagingReady } = useAuthProfile()
  const [SetChat, { isLoading: isLoadingSetChat, isSuccess: isSuccessSetChat, isError: isErrorSetChat, data: setChatData, originalArgs //isError: isErrorSetChat, error: errorSetChat, reset 
  }] = useCreateOrJoinOpenChatMutation()
  //const { data: profiles, isLoading } = useGetProfilesPinDataForDefaultPointQuery(); - not used
  const AIState_publicPinClicked = useTypedSelector((state: RootState) => state.dataProcessing.publicPinClicked)
  const navigate = useNavigate()
  const [openLoginDialog, setOpenLoginDialog] = React.useState(false)
  const leftSideComponentWithOptionsState: LeftSideComponentWithOptions = useTypedSelector(
    (state: RootState) => state.dataProcessing.leftSideComponentWithOptions
  );
  const AIState_aiProfileOptions = leftSideComponentWithOptionsState.options

  const lastgetSearchByStoryyStatusArgsAndData = useSelector((state: any) =>    selectLastgetSearchByStoryyStatusArgsAndData(state));

  
  const searchState = useSelector((state: any) => state.search, (prev, next) => {
    if (!next.center) return true
    if (!prev.center) return false
    return prev.center.lat === next.center.lat && prev.center.lng === next.center.lng
  });


  //need to do this to get user set up after loggin in. the announcement chat is created and the Guest user (361) is too for unautheticeted 
  React.useEffect( () => {
    if (! messagingReady &&  isSuccess){
    //.const idToUseForSendbird =   authStatus === 'unauthenticated'? 11111: loggedInProfile.id
    SetChat(  {ids:[loggedInProfile.id], ... GetAnnouncementsChannelUrlForEnvironment(), channelType: "announcements"})
    }
  }, [isSuccess])

  React.useEffect(() => {
    if (lastgetSearchByStoryyStatusArgsAndData.data?.length >0){//searchStoryState.storySearch.data.length > 0) {
      const element = document.getElementById('storySearchResult') as HTMLElement;
      if (element) {
        //console.log("test")
          element.scrollIntoView(true);
      }
    }
  }, [  lastgetSearchByStoryyStatusArgsAndData.status === QueryStatus.fulfilled]) //searchStoryState.storySearch.complete]); 

  const center = searchState.center;
  if (searchState.status.isUnInitialized) {
    return null
  }
  const showAccountPromotion = authStatus !== 'authenticated' //&& ((leftSideComponentWithOptionsState.options?.showAccountPromotion)??false)

  const handleHomeClick = () => {
    dispatch(getSearchByCoordinates({ lat: loggedInProfile.lat, lng: loggedInProfile.lng }));
    //leocenterno need the below ceters probobly by showing the marker on the map dispatch(setCenter({ lat: profile.lat, lng: profile.lng }))
    dispatch(activateMarker({ id: loggedInProfile.id, disableHover: true }))
    dispatch(setPublicPinClicked(loggedInProfile.id))
    //dispatch(getProfileFromID(profile.id))
  }

  function handleLocatePinClick() {
    dispatch(getSearchByCoordinates({ lat: loggedInProfile.lat, lng: loggedInProfile.lng }));
    dispatch(activateMarker({ id: loggedInProfile.id, disableHover: true }))
  }

  function handleEditPinClick() {
    dispatch(showProfile({ isForced: true }))
  }


  if (isError) {
    const profileError = error.data ? error.data.error : error.message ?? ''
    return (<>{profileError}</>)
  }
  if (isAuthProfileLoading)
    return (<CircularProgressCentered />)

  const noStory = loggedInProfile ? !loggedInProfile?.comment?.trim() : true
  const showProfileToComplete: boolean = loggedInProfile ? !loggedInProfile.is_completed : true //loggedInProfile.zipcode === null || loggedInProfile.communicate_with_others === null || !loggedInProfile.provide_information_for_research === null || !loggedInProfile.receive_emails === null : false

  //const showAISummary = AIState_publicPinClicked ? ((!noStory && !showProfileToComplete) ) : false
  const showAISummary = AIState_publicPinClicked
  // const environmentNODE_ENV: string = process.env.NODE_ENV || 'development';
  // const environmentREACT_APP_ENV: string = process.env.REACT_APP_ENV || 'test'
  if (showAISummary) {
    return (<AISummary bottomSheetRef={bottomSheetRef} pinToUse={AIState_publicPinClicked} showAccountPromotion={showAccountPromotion} showMessageButton={AIState_aiProfileOptions?.showMessageButton} showViewUserStoryAnalytics={AIState_aiProfileOptions?.showViewUserStoryAnalytics} />)
  } else {
    return (
      <Box sx={{ display: 'flex', borderRight: 1, borderColor: '#E0E0E0', overflow: 'auto', width: '100%', height: { xs: 'auto', sm: 'auto', md: 'calc(100vh - 80px)' }, pt: 2 /* backgroundColor: 'yellow'*/ }}>

          <Stack sx={{ width: '100%',p:2 }} direction="column" gap={3}>
            {authStatus == 'authenticated' &&
              <>
                <Stack direction="column" sx={{ alignContent: 'center', alignItems: 'center', justifyContent: 'center' }} gap={2}>
                  <Stack direction="row" sx={{display:'flex', width:'100%', justifyContent:'space-between', alignItems:'center'}}>
                    <Typography variant="h6" sx={{fontSize:{xs:16,sm:16,md:18}}}>
                      Hi, {loggedInProfile.name_public}
                    </Typography>
                    <Button sx={{ color: '#656EFF', borderRadius:8, fontSize:14 }} onClick={handleHomeClick} variant="outlined" disableElevation endIcon={<ArrowForwardIosIcon />}>
                      My Profile
                    </Button>
                  </Stack>
                </Stack>
                <Divider sx={{width:'100%'}}/>
             
              </>
            }
            {authStatus == 'unauthenticated' &&
              <Stack direction="column" gap={2} sx={{p:3, backgroundColor:'#F5F3FF', borderRadius:6}}>
                <Typography variant="h6" sx={{fontSize:{xs:16,sm:16,md:18}}}>
                  Get Started
                </Typography>
                <Typography variant="body1">
                  Sign in or sign up to add your pin, connect with others with similar experiences, access resources, and more.
                </Typography>
                <Stack direction="row" gap={1}>
                  <Button variant="contained" sx={{color: 'white', backgroundColor:'#656EFF', borderRadius:8, borderColor:'#F5F3FF' }} onClick={() => {navigate('/sign-in')}} disableElevation>
                    Sign in
                  </Button>
                  <Button variant="outlined" sx={{borderRadius:8, color:'#656EFF', borderColor:'#656EFF'}} onClick={() => {navigate("/SignUp")}} disableElevation>
                    Sign up
                  </Button>
                </Stack>
              </Stack>
            }
            <StorySearchSummary />
            <Stack direction="row" sx={{display:'flex', width:'100%', justifyContent:'space-between', alignItems:'center'}}>
              <Typography variant="h6" sx={{fontSize:{xs:16,sm:16,md:18}}}>
                My Pin
              </Typography>
              <Stack direction="row" gap={1}>
                {(authStatus == 'authenticated' && loggedInProfile.is_completed) &&
                  <>
                    <PlaceIcon sx={{color:'#656EFF'}}/>
                    <Typography variant="body1" sx={{color:'#777777'}}>
                      {loggedInProfile.formattedsearchterm}
                    </Typography>
                  </>
                }
                {authStatus == 'unauthenticated' && 
                  <Button variant="outlined" sx={{borderRadius:8, color:'#656EFF', borderColor:'#656EFF'}} onClick={() => {setOpenLoginDialog(true)}} disableElevation startIcon={<AddLocationAltIcon />}>
                    Add My Pin
                  </Button>
                }
              </Stack>
            </Stack>
            {(authStatus == 'authenticated' && !loggedInProfile.is_completed) &&
                <Stack direction="column" gap={2} sx={{p:3, backgroundColor:'#F5F3FF', borderRadius:6}}>
                  <Typography variant="body1">
                    Looking for members nearby? Add your pin to get started!
                  </Typography>
                  <Button variant="contained" sx={{borderRadius:8, backgroundColor:'#656EFF'}} onClick={handleEditPinClick} disableElevation startIcon={<AddLocationAltIcon />}>
                    Add My Pin
                  </Button>
                </Stack>
              }
            {(authStatus == 'authenticated' && loggedInProfile.is_completed) &&
              <Stack direction="row" sx={{display:'flex', width:'100%', justifyContent:'space-between', alignItems:'center'}}>
                <Button sx={{ pt:2,pb:2,pl:3,pr:3, color: '#46497C', backgroundColor:'#F5F3FF', borderRadius:8, fontSize:14, borderColor:'#F5F3FF' }} onClick={handleLocatePinClick} variant="outlined" disableElevation startIcon={<PlaceIcon />}>
                  Locate My Pin
                </Button>
                <Button sx={{ pt:2,pb:2,pl:3,pr:3, color: '#46497C', backgroundColor:'#F5F3FF', borderRadius:8, fontSize:14, borderColor:'#F5F3FF' }} onClick={handleEditPinClick} variant="outlined" disableElevation startIcon={<EditIcon />}>
                  Edit My Pin
                </Button>
              </Stack> 
            }
            {center && <PatientSummary center={center} />}
            {messagingReady && <OpenChannelMain channelUrl=  {GetAnnouncementsChannelUrlForEnvironment().channelUrl} />}
            <Divider sx={{width:'100%'}}/>
            <ResourceMini />
            <Divider sx={{width:'100%'}}/>
            <Typography variant="h6" sx={{ justifyItems: "center", alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>
              Explore Patient Connect
            </Typography>
            <NavMini />
          </Stack>
          <Dialog onClose={()=>{setOpenLoginDialog(false)}} open={openLoginDialog}>
            <Stack direction="column" gap={2} sx={{p:3, backgroundColor:'#F5F3FF', borderRadius:6}}>
              <Typography variant="h6" sx={{fontSize:{xs:16,sm:16,md:18}}}>
                Get Started
              </Typography>
              <Typography variant="body1">
                Sign in or sign up to add your pin, connect with others with similar experiences, access resources, and more.
              </Typography>
              <Stack direction="row" gap={1}>
                <Button variant="contained" sx={{color: 'white', backgroundColor:'#656EFF', borderRadius:8, borderColor:'#F5F3FF' }} onClick={() => {navigate('/sign-in')}} disableElevation>
                  Sign in
                </Button>
                <Button variant="outlined" sx={{borderRadius:8, color:'#656EFF', borderColor:'#656EFF'}} onClick={() => {navigate("/SignUp")}} disableElevation>
                  Sign up
                </Button>
              </Stack>
            </Stack>
          </Dialog>
        </Box>
  
    );
  }

}