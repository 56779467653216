import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCurrentUser, fetchAuthSession } from "aws-amplify/auth";
//import axios from 'axios';
export interface UserAttributeState {
  userAttributes: any | null,
  status: any,
  HUBEvent?: string,
  HUBSignInEventData: any,
  customState: any
  //isFederated: boolean | null  //isFederated is in userAttributes
}
export const getUserAttributes = createAsyncThunk("user/getUserAttributes", async (_, thunkAPI) => {

  try {
    const currentUser = await getCurrentUser()
    try {
      const authSession: any = await fetchAuthSession()
      const isFBbIdentityType = (authSession.tokens.idToken.payload.identities ?? []).filter((id: any) => id.providerType === "Facebook").length > 0
      const nickname = authSession.tokens.idToken.payload.nickname
      const email = authSession.tokens.idToken.payload.email
      const userName = authSession.tokens.idToken.payload["cognito:username"]
      const userAttributes = { userName: userName, nickname: nickname, email: email, sub: authSession.tokens.idToken.payload.sub, ...(isFBbIdentityType ? { isFederated: true } : {}) }
      //const userAttributes = await fetchUserAttributes();
      //console.log(userAttributes)
      return userAttributes
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: `[userId:${currentUser.userId}  username:${currentUser.username}]  ${error.message}` });
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: `${error.message}` });
  }
})

const initialState: UserAttributeState = {
  userAttributes: null,
  //isFederated:null, 
  status: { isIdle: true },
  HUBEvent: undefined,
  HUBSignInEventData: undefined,
  customState: undefined
};
export const userAttributesSlice = createSlice({
  name: 'userattributes',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    reset:(state) => {
      state.userAttributes = { ...initialState }
    },
    setFederated: (state, action) => {
      state.userAttributes = { ...state.userAttributes, isFederated: action.payload }
    },
    setSignInDetails: (state, action) => {
      state.HUBSignInEventData = action.payload 
      state.HUBEvent = "signedIn"
    },
    setSignOutEvent: (state, action) => {      
      state.HUBEvent = action.payload.event
    },   
    setCustomState: (state, action) => {
      state.customState = action.payload 
      state.HUBEvent = "customState"
    },

  },
  extraReducers: (builder) => {
    // global
    builder.addCase(getUserAttributes.pending, (state, action) => {
      state.status = { isLoading: true, isSuccess: false, isError: false, error: undefined }
    })
    builder.addCase(getUserAttributes.fulfilled, (state, action) => {
      state.userAttributes = action.payload;
      state.status = { isLoading: false, isSuccess: true, isError: false, error: undefined }
    });
    builder.addCase(getUserAttributes.rejected, (state, action: any) => {
      const message = action.payload.error
      state.status = { isLoading: false, isSuccess: false, isError: true, error: `rejected: ${message}` }
    })
  }

});

export const {setCustomState, reset, setFederated , setSignInDetails,  setSignOutEvent} = userAttributesSlice.actions;


export default userAttributesSlice.reducer;
