import React, { useEffect, useState } /*, { ReactNode }*/ from 'react';
import { Alert, Box, Link, Stack, Typography } from '@mui/material';
import { useDispatch/*, useSelector*/ } from 'react-redux';
import { ReactComponent as AISVG } from '../../assets/ai_star.svg';
import { AnalyticType,/* AnalyticUsersClickedType, getUsersFromAnalyticsClicked, */setAnalyticUsersClicked } from "../../features/dataProcessingSlice";
import ChatIcon from '@mui/icons-material/Chat';
//import { AILoading } from "./AILoading";
import { Coordinates } from '../../features/searchSlice';
import { LoadingButton } from '@mui/lab';
import { StartChat } from '../markers/MMMarkers';
import { DirectChatUsers, useSetNamedChatManyUsersMutation, useSetChatUserMutation, useCreateOrJoinAnalyticsGroupChatMutation, chatApi } from '../../features/ChatSlice';
import { useAuthProfile } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';
//const haversine = require('haversine')

interface SummarizedAIProfileComponentProps {
    //children: ReactNode;    
    profileToShow: any
    aiSummary: any
    loggedInUserprofileCoordinates: Coordinates
}
export const SummarizedAIProfile: React.FC<SummarizedAIProfileComponentProps> = ({ profileToShow, aiSummary, loggedInUserprofileCoordinates }) => {
    const dispatch = useDispatch<any>()
    const navigate = useNavigate()
    const [SetChat, { isLoading: isLoadingSetChat, isSuccess: isSuccessSetChat, isError: isErrorSetChat, data: setChatData, originalArgs //isError: isErrorSetChat, error: errorSetChat, reset 
    }] = useCreateOrJoinAnalyticsGroupChatMutation()
    const { authStatus, profile: profileLoggedIn, isLoading: isLoadingpProfileLoggedIn,  messagingReady  // isLoading: isLoadingProfileLoggedIn, isError: isErrorProfileLoggedIn, error: errorProfileLoggedIn, userAttributesStateStatus 
    } = useAuthProfile()

    function userLink(analyticsVariable: string, analyticsArrayIndex: number, type: AnalyticType) {
        dispatch(setAnalyticUsersClicked({ analyticsArrayIndex: analyticsArrayIndex, analyticType: type, analyticsVariable: analyticsVariable }))
    }
  
    useEffect(() => {
        if (isSuccessSetChat ){//&& messagingReady) {
            //console.log("setChatData returned by useCreateOrJoinAnalyticsGroupChatMutation()", setChatData)
            // if( !messagingReady)
            //     dispatch(chatApi.util.invalidateTags(['Ready']))
            navigate("/Messages", { state: { chatAndUserData: setChatData } })//navigate("/Messages", { state: { messageTo: profile.iam_id, id: profile.id } })
        }
        if (isErrorSetChat) {
            // Handle the error, and you can access originalArgs here
            //console.log('Original Args:', originalArgs); // Logs { id: 1, name: 'John Doe' }            
          }
    }, [isSuccessSetChat, messagingReady])


    if (aiSummary?.length > 0) {
        return (
            aiSummary.map((row: any, rowIndex: number) => {
                const numericVarVal = (row.analytics?.users?.length && row.type === "numeric") ?
                    (row.analytics.aggregate.min !== row.analytics.aggregate.max ?
                        ` between ${row.analytics.aggregate.min} and ${row.analytics.aggregate.max}` :
                        ` equal to ${row.analytics.aggregate.min}`) : null
                const numericChatName = numericVarVal ? `Members matched by ${row.formattedVariable}: ${numericVarVal}` : null
                const textVarVal = (row.analytics && Array.isArray(row.analytics) && row.analytics.length > 0 && row.type === "text") ?
                    (row.analytics.length > 1 ? (" were matched based on similar data such as: " + (row.analytics[0].value !== row.analytics[1].value ? row.analytics[0].value + ", " + row.analytics[1].value : row.analytics[0].value))
                        : " was found with similar data: " + row.analytics[0].value) : null
                const textChatName = textVarVal ? `Members matched by ${row.formattedVariable}: ${textVarVal.split(":")[1].trim()}` : null
                return (
                    <div key={row.var + "container" + rowIndex.toString()}>
                        <Typography key={row.var + "header"} variant="subtitle1" sx={{ pt: 2, fontWeight: 'bold' }}>
                            {row.formattedVariable}
                        </Typography>
                        <Typography key={row.var + "value"} variant="body1" sx={{ pb: 1, color: '#777777', fontWeight: 500 }}>
                            {row.formattedValue}
                        </Typography>
                        {(row.analytics?.users?.length && row.type === "numeric") &&
                            <Box sx={{ border: 1, borderRadius: 3, borderColor: '#EDEEFF', backgroundColor: '#EDEEFF', p: 2 }}>
                                <Stack direction="row" alignItems="center" gap={1}>
                                    <Box sx={{ width: 20 }}>
                                        <AISVG style={{ height: 20, width: 20 }} />
                                    </Box>
                                    {row.analytics?.users?.length && <Typography key={row.var + "analytics"} variant="body2">
                                        {`There ${(row.analytics.users.length > 1) ? "are " : "is "}`}
                                        <Link component="button" onClick={() => userLink(row.var, rowIndex, "numeric")}>
                                            {`${row.analytics.users.length} member${(row.analytics.users.length > 1) ? "s" : ""}`}
                                        </Link>
                                        {<>{numericVarVal} for {row.formattedVariable} </>}
                                    </Typography>}
                                </Stack>
                            </Box>
                        }
                        {(row.analytics && Array.isArray(row.analytics) && row.analytics.length > 0 && row.type === "text") &&
                            <Box sx={{ border: 1, borderRadius: 3, borderColor: '#EDEEFF', backgroundColor: '#EDEEFF', p: 2 }}>
                                <Stack direction="row" alignItems="center" gap={1}>
                                    <Box sx={{ width: 20 }}>
                                        <AISVG style={{ height: 20, width: 20 }} />
                                    </Box>
                                    <Typography key={row.var + "analytics"} variant="body2">
                                        <Link component="button" onClick={() => userLink(row.var, rowIndex, "text")}>
                                            {`${row.analytics.length} similar member${row.analytics.length > 1 ? "s " : " "}`}
                                        </Link>
                                        {textVarVal}
                                    </Typography>
                                </Stack>
                                <LoadingButton loadingPosition="start" id="analytics_users_chat" loading={isLoadingSetChat && originalArgs?.col===row.var} onClick={() => {
                                    const column = row.var
                                    const ids = [profileLoggedIn.id, ...profileToShow.id !== profileLoggedIn.id?[profileToShow.id]:[],  ...row.analytics.map((u: any) => u.id)]
                                    //SetChat({  loggedInId: profileLoggedIn.id,  ids: ids, col: column, value: row.formattedValue })
                                    StartChat(ids, authStatus, () => navigate("/Messages"), (setChatArg: DirectChatUsers) => SetChat({  analyticsProfileId: profileToShow.id , initiatorId: profileLoggedIn.id,  ids: ids, col: column, value: row.formattedValue }), true)                                    
                                }} variant="contained" disableElevation startIcon={<ChatIcon />} sx={{ mt: 1, backgroundColor: '#656EFF', width: '100%' }}>
                                    Chat with these members
                                </LoadingButton>
                                {(isErrorSetChat && originalArgs?.col === row.var) && <Alert severity="error">Oops... Error occurred while  initiating a chat.  Please try again later.</Alert>}
                            </Box>
                        }
                    </div>)
            }
            )
        )
    } else {
        return (
            <Typography variant="body1" sx={{ pb: 1, color: '#777777' }}>
                No relevant clinical information was found
            </Typography>
        )
    }

}



// {row.analytics[0].users.length > 1
//     ? "There are "
//     : "There is "
// }
// <Link component="button" onClick={() => userLink(row.analytics[0].users, "numeric")}>
//     {row.analytics[0].users.length > 1
//         ? row.analytics[0].users.length + " users"
//         : row.analytics[0].users.length + " user"
//     }
// </Link>
// {row.analytics[0].aggregate.min != row.analytics[0].aggregate.max
//     ?
//     " between "
//     + row.analytics[0].aggregate.min + " and " + row.analytics[0].aggregate.max
//     + " for "
//     + row.var.toLowerCase().split("_").join(" ")
//     :
//     " equal to "
//     + row.analytics[0].aggregate.min
//     + " for "
//     + row.var.toLowerCase().split("_").join(" ")
// }


// {/* <Link component="button" onClick={() => userLink(row.analytics, "text")}>
//                                         {row.analytics.length > 1
//                                             ? row.analytics.length + " similar users "
//                                             : row.analytics.length + " similar user "
//                                         }
//                                     </Link>
//                                     {row.analytics.length > 1
//                                         ? (row.analytics[0].value.split(",").join(", ").toLowerCase() != row.analytics[1].value.split(",").join(", ").toLowerCase())
//                                             ? " were matched based on similar data such as: " + row.analytics[0].value.split(",").join(", ") + ", " + row.analytics[1].value.split(",").join(", ")
//                                             : " were matched based on similar data such as: " + row.analytics[0].value.split(",").join(", ")
//                                         : " was found with similar data: " + row.analytics[0].value.split(",").join(", ")
//                                     } */}
