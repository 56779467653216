/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
import awsconfig from './aws-exports';

export const getAwsConfig: ( baseName: string )=> any =( baseName) =>{
    const redirectSignIn = awsconfig.oauth.redirectSignIn.replace('/callback', `${baseName?`/${baseName}`:'' }/callback`)
    const redirectSignOut = awsconfig.oauth.redirectSignOut.replace('/out', `${baseName?`/${baseName}`:'' }/out`)
    awsconfig.oauth.redirectSignIn = redirectSignIn
    awsconfig.oauth.redirectSignOut = redirectSignOut
    console.log("AWS config:", awsconfig.oauth) 
    return awsconfig
}

export default getAwsConfig;
