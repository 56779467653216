import { Hub } from "aws-amplify/utils";
import { showDeleteAccount } from "./features/dialogSlice";
import { setCustomState, setFederated, setSignInDetails, setSignOutEvent } from "./features/userAttributesSlice";
import { store } from './app/store';

export const listenForAuthEvents = () => {
  Hub.listen("auth", ({ payload }) => {
    switch (payload.event) {
      case "signInWithRedirect":
        //const user = await getCurrentUser();
        console.log('HUB' ,payload.event, payload);
        store.dispatch(setFederated(true))
        break;
      case "signInWithRedirect_failure":
        console.log("signInWithRedirect_failure: ", payload);
        break;
      case "customOAuthState":
        const state = payload.data; // this will be customState provided on signInWithRedirect function         
        //store.dispatch(showDeleteAccount(state === "deleteProfile"))
        store.dispatch(setCustomState(payload.data))
        break;
      case "signedIn":
        store.dispatch(setSignInDetails(payload.data))
        break
      case "signedOut":
        store.dispatch(setSignOutEvent(payload))
        break 
      default:
        //console.log(state?state:'no state');
        break;

    }
  });
}