import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

export const formatPhoneNumber: (phoneNumberString: string) => string = (phoneNumberString) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');

  if (cleaned.length !== 10) {
    return phoneNumberString; // Return original input if it's not a 10-digit number
  }

  const part1 = cleaned.substring(0, 3);
  const part2 = cleaned.substring(3, 6);
  const part3 = cleaned.substring(6);

  return `(${part1}) ${part2}-${part3}`;
}



export function getCookie(name: string) {
  const cookies = document.cookie.split(';');
  for (let cookie of cookies) {
    const [cookieName, cookieValue] = cookie.trim().split('=');
    if (cookieName === name) {
      try {
        return decodeURIComponent(cookieValue);
      } catch (error: any) {
        console.error(`Error decoding ${name} Cookie[${cookieValue}]:`, error.message);
        return null
      }
    }
  }
  return null;
}

export const getDisclaimerCookie: () => boolean = () => getCookie("disclaimer") === "agreed"
export const setDisclaimerCookie = (days: number = 30) => {
  const date = new Date();
  // Calculate the expiration date
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000)); // Convert days to milliseconds
  document.cookie = `disclaimer=agreed; expires=${date.toUTCString()}; path=/`;
}
export function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
export function stringAvatar(name: string) {
  const names = name.split(' ')
  const splitName = names.length > 1 ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` : name.substring(0, 2)

  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: splitName,
  };
}

export const getScrollbarWidth = () => {
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  outer.style.width = '50px';
  outer.style.height = '50px';
  document.body.appendChild(outer);

  const inner = document.createElement('div');
  inner.style.width = '100%';
  inner.style.height = '100%';
  outer.appendChild(inner);

  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
  if (outer.parentNode)
    outer.parentNode.removeChild(outer);

  return scrollbarWidth;
};


export const loadYouTubeAPI = (): Promise<void> => {
  return new Promise((resolve, reject) => {

    // Check if the script has already been loaded
    if (window.YT && typeof window.YT.Player === 'function') {
      resolve();
    } else {
      // Create a <script> element to load the YouTube Iframe Player API script
      const script = document.createElement('script');
      script.src = 'https://www.youtube.com/iframe_api';
      script.onload = () => {
        // Once the script is loaded, resolve the promise
        resolve();
      };
      script.onerror = (error) => {
        // If there's an error loading the script, reject the promise
        reject(error);
      };
      // Append the <script> element to the document body
      document.body.appendChild(script);
    }
  });
};


export const printFetchBaseQueryError = (error: FetchBaseQueryError) => {
  if ('status' in error) {
    const errMsg = 'error' in error ? error.error : JSON.stringify(error.data);
    const ret = errMsg//`${error.status}(${errMsg})`;
    return ret
  } else {
    return `Unknown error ${error}`
  }
};

const isFetchBaseQueryError = (error: any): error is FetchBaseQueryError => {
  return 'status' in error;
};

const isSerializedError = (error: any): error is SerializedError => {
  return 'message' in error;
};

export const printError = (error: FetchBaseQueryError | SerializedError | undefined): string => {
  if (isFetchBaseQueryError(error)) {
    return printFetchBaseQueryError(error);
  } else if (isSerializedError(error)) {
    return printSerializedError(error);
  } else {
    return `Unknown error ${error}`;
  }
};

const printSerializedError = (error: SerializedError): string => {
  return `${error.message}`;
};

export function isStringEmptyOrWhitespace(str: string | undefined | null) {
  return typeof str === 'undefined' || str === null || str.trim().length === 0;
}
export default loadYouTubeAPI;

const imageCache: Record<string, string> = {};

export const loadImageSync = (baseName: string): string | null => {
  return imageCache[baseName] || null;
};

export const loadImage = async (baseName: string, fallbackName: string = ''): Promise<string | null> => {
  if (imageCache[baseName]) {
    return imageCache[baseName];
  }

  try {
    const imageModule = await import(`../assets/${baseName}`);
    const imagePath = imageModule.default;
    imageCache[baseName] = imagePath;
    return imagePath;
  } catch (error) {
    if (fallbackName) {
      try {
        const imageModule = await import(`../assets/${fallbackName}`);
        const imagePath = imageModule.default;
        imageCache[baseName] = imagePath;
        return imagePath;
      } catch (error1) {
        console.error('Failed to load image:', error1);
        return null
      }
    }
    console.error('Failed to load image:', error);
    return null;
  }
};

// // Usage
// const baseName = '2';
// let imagePath = loadImageSync(baseName); // Try synchronous access

// if (!imagePath) {
//   (async () => {
//     imagePath = await loadImage(baseName);
//     console.log(imagePath); // Use it once loaded
//   })();
// }


