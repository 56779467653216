import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Autocomplete, { AutocompleteChangeDetails, AutocompleteChangeReason, AutocompleteProps, AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import { InputAdornment, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { countries as options, CountryType } from './CountryCodes';
import { FlagSharp } from '@mui/icons-material';

const postalCodeLabels = {
  US: 'ZIP Code',
  CA: 'Postal Code',
  GB: 'Postcode',
  AU: 'Postcode',
  DE: 'Postleitzahl (PLZ)',
  FR: 'Code Postal',
  JP: 'Postal Code',
  CN: '邮政编码 (Yóuzhèng biānmǎ)',
  IN: 'PIN Code',
  IT: 'CAP',
  ES: 'Código Postal',
  ZA: 'Postal Code',
  BR: 'CEP',
  MX: 'Código Postal',
  NL: 'Postcode',
  RU: 'Почтовый индекс',
};


export interface CountrySelectProps {
  onChange: ((event: React.SyntheticEvent<Element, Event> | undefined | null, value: CountryType | undefined | null, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any> | undefined) => void) | undefined
  error: boolean
  helperText: string | undefined
  value: CountryType | undefined | null
  //label: string
}


export const CountrySelect: React.FC<CountrySelectProps> = ({ onChange, error, helperText, value }) => {
  const [inputChanged, setInputChanged] = React.useState(false);
  const [shouldOpen, setShouldOpen] = useState(false);
  const [preClickShouldOpen, setPreClickShouldOpen] = useState(shouldOpen);
  const [errorCountry, setErrorCountry] = useState(false);
  const [flagLoadingState, setFlagLoadingState] = useState({ isLoading: true, isSuccess: false, isError: false });
  const [errorFlagsUrl, setErrorFlagsUrl] = useState<string | null>(null);
  const [loadedFlags, setLoadedFlags] = useState<JSX.Element[]>([]); // State to hold preloaded flag images
  const [inputValue, setInputValue] = React.useState(value?.label);
  const [selectedCode, setSelectedCode] = React.useState<string | undefined | null>((value?.code) ? value?.code : null)
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const flagsReady = flagLoadingState.isSuccess

  React.useEffect(() => {
    const preloadImagesAndSvgs = async () => {
      const loadPromises = options.map(country => {
        const url = `https://flagcdn.com/${country.code.toLowerCase()}.svg`
        return new Promise<void>((resolve, reject) => {
          const img = new Image();
          img.src = url;
          img.onload = () => {
            // Create the img element as soon as it's loaded
            const flagImage = (
              <img
                key={country.code}
                src={url}
                alt="Flag"
                style={{ margin: '10px', maxWidth: '50px', maxHeight: '30px' }} // Set size for flags
              />)
            country.flagJSX = flagImage
            setLoadedFlags((prev) => ({
              ...prev,
              [country.code]: flagImage, // Store each loaded flag by its URL
            }));
            resolve();
          };
          img.onerror = () => {
            reject(new Error(`Failed to load image: ${url}`));
          };
        });
      });
      try {
        await Promise.all(loadPromises);
        setFlagLoadingState({ ...flagLoadingState, isSuccess: true }); // All images are preloaded successfully
      } catch (err: unknown) { // Using unknown for better type safety
        if (err instanceof Error) {
          setErrorFlagsUrl(err.message); // Set the error state using the message from the Error object
        } else {
          setErrorFlagsUrl('An unknown error occurred'); // Handle unexpected errors
        }
        setFlagLoadingState({ ...flagLoadingState, isError: true });
      }
    };
    preloadImagesAndSvgs();
  }, []); // Depend on urls to reload if they change

  useEffect(() => {
    setSelectedCode(value?.code)
  }, [value])



  const onChangeLocal: ((event: React.SyntheticEvent<Element, Event> | undefined | null, value: any | undefined, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any> | undefined) => void) = (event, value, reason, details) => {

    setSelectedCode((value?.code))
    //setShouldOpen( includes && value?.label !== newInputValue);
    if (onChange) {
      onChange(event, value, reason, details)
    }
    // if (!value) {
    //   if (inputRef.current) {
    //     inputRef.current.focus(); // Focus the input element
    //   }
    // }
  };

  const handleOpen = (event: React.SyntheticEvent) => {
    //console.log('In HandleOpen Local', value?.label, inputValue, options.length)
    const shouldOpenLocal = value?.label !== inputValue && !!options.length//includes && !matches &&
    //console.log('In HandleOpen Local', shouldOpenLocal)
    //console.log('In HandleOpen', shouldOpen)
    if (event.type == "click") {
      setShouldOpen(preClickShouldOpen || !!value);
    }
  };

  const handleClose = (event: React.SyntheticEvent, reason: string) => {
    if (event.type == "click") {
      setPreClickShouldOpen(shouldOpen)
      setShouldOpen(false);
    }
  };

  console.log('should open? ', shouldOpen)
  return (
    <>
      <Autocomplete
        id="country-select-demo"
        sx={{ width: '100%', backgroundColor: 'white' }}
        options={options}
        autoHighlight
        getOptionLabel={(option: any) => option.label}
        isOptionEqualToValue={(option: CountryType, value: CountryType) => {
          //if( !value)            return option.label == 'United States'
          return value?.label === option.label
        }}
        value={value}
        onChange={onChangeLocal}
        inputValue={inputValue ?? ''}
        onInputChange={(event, newInputValue) => {
          //newInputValue = newInputValue.trim()
          if (event && (event.type === 'change' || event.type === 'click' || event.type === "keydown")) {
            setInputValue(newInputValue);  // Update input value normally
         
            const matches = options.filter(option =>
              option.label.toLowerCase() === newInputValue.toLowerCase()
            );
            const includes = options.some(option =>
              option.label.toLowerCase().includes(newInputValue.toLowerCase())
            );
            console.log(includes)
            setInputChanged(!matches.length && newInputValue !== value?.label)
            const isErrorCountry = !includes && !!newInputValue?.length
            setErrorCountry(isErrorCountry);
            console.log(includes, !matches, value?.label !== newInputValue, !!options.length)

            setShouldOpen(includes && matches.length < 1 && value?.label !== newInputValue && !!options.length);
            if(matches.length === 1) {
              setInputValue(matches[0].label);
              onChangeLocal( undefined, matches[0], 'selectOption', undefined)

            }
            //setPreClickShouldOpen(includes && !matches && value?.label !== newInputValue && !!options.length);
          }
          if (!value?.label || value?.label === newInputValue) {
            setSelectedCode(value?.code)
          } else {
            onChangeLocal(null, null, 'clear', undefined)
            setSelectedCode(null)
          }

          // //setInputChanged( !options.find( o=> o.label=== newInputValue) &&  newInputValue !== value?.label )
          // const matches = options.some(option =>
          //   option.label.toLowerCase() === newInputValue.toLowerCase()
          // );
          // const includes = options.some(option =>
          //   option.label.toLowerCase().includes(newInputValue.toLowerCase())
          // );
          // setInputChanged(!matches && newInputValue !== value?.label)
          // const isErrorCountry = !includes && !!newInputValue?.length
          // setErrorCountry(isErrorCountry);
          // setShouldOpen( includes && !matches && value?.label !== newInputValue);

        }}
        onBlur={() => {
          if (inputChanged && value && value?.label !== inputValue) { //inputChanged && value &&
            // Prevent reverting to the last selected value if the user is typing
            onChangeLocal(null, null, 'clear', undefined) // Reset the selection if input doesn't match the selected value
          }
        }}
        renderOption={(props, option: any,) => (
          <Box key={option.code} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            {flagsReady && option.flagJSX}
            {option.label}
          </Box>
        )}
        open={shouldOpen}
        onOpen={handleOpen} // Open dropdown on clicking the down arrow or focusing
        onClose={handleClose} // Close dropdown when needed
        renderInput={(params: AutocompleteRenderInputParams) => {
          const { value } = params.inputProps
          const { color, size, ...inputPropsSansColor } = params.inputProps
          const helperTextCombined = `${helperText ? helperText : ''}${errorCountry ? `${helperText ? '. ' : ''}No country matching ${inputValue}` : ``}`
          return (
            <>
              <TextField
                inputRef={inputRef}
                {...params}
                error={errorCountry || !!error}
                helperText={helperTextCombined}
                label="Select country"
                fullWidth
                InputProps={{
                  ...params.InputProps, autoComplete: 'new-password',
                  startAdornment: <InputAdornment position="start"> <>
                    {selectedCode &&
                      <img
                        src={`https://flagcdn.com/${selectedCode.toLowerCase()}.svg`}
                        width="45"
                        alt={selectedCode}></img>
                    }

                  </></InputAdornment>,
                  endAdornment: (
                    <React.Fragment>
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  )
                }}
              />


            </>
          )
        }}
      />
    </>);
}


