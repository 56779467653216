import React from "react";
import { /*GoogleMap, Marker, DirectionsRenderer, useLoadScript,*/ Polyline } from "@react-google-maps/api";
import { InstitutionMarker, ShowMarkerType } from "../markers/InstitutionMarkers";
import { useBikeRoute } from "./BikeRouteHook";
import { shallowEqual, useSelector } from "react-redux";




const DottedRoute: React.FC<{ zoom: number }> = ({ zoom }) => {
    const institutionTypesState = useSelector((state: any) => state.filter.showCareCenters, shallowEqual)
    const unknownIsGood = useSelector((state: any) => state.filter.unknownIsGood)
    const { /*directions, */bikeStops, routePathPastCurrent, routePathFuture } = useBikeRoute(true)
    const hasPath = routePathPastCurrent.length > 0
    // const circlIcon = {
    //     path: google.maps.SymbolPath.CIRCLE, // Dot symbol
    //     scale: 3, // Size of the dots
    //     strokeWeight: 50, // Thicker dashes
    //     //fillOpacity: 3,
    //     strokeColor: "#D3D3D3", // Light grey color
    //     //fillColor: 'grey'//"#FF0000", // Dot color (red)
    // }
    //rgb(206,250,255)
    const dashIconFuture = {
        path: "M 0,-1 0,1", // Dash pattern
        strokeOpacity: 20,
        strokeWeight: 5, // Thickness of dashes
        strokeColor: 'grey',//"#D3D3D3", // Light grey color
        //fillColor: 'red'
    }
    //grea'rgb(206,250,255)',//"#D3D3D3", // Light grey color
    const dashIconPastCurrent ={
        path: "M 0,-1 0,1", // Dash pattern
        strokeOpacity: 20,
        strokeWeight: 4, // Thickness of dashes
        strokeColor: 'rgb(26, 171, 198)'//rgb(206,250,255)',//"#D3D3D3", // Light grey color
        //fillColor: 'red'
    }
    const show = ShowMarkerType('BSTP', institutionTypesState, unknownIsGood)
    if (!show)
        return null
    return (<>
        {bikeStops?.map(
            (row, index) => (ShowMarkerType('BSTP', institutionTypesState, unknownIsGood) && <InstitutionMarker zoom={zoom} id={row.id} data={row} key={row.id} />)
        )
        }
        {/* {directions && <DirectionsRenderer options={{
          directions: directions ,
          suppressMarkers: true, // Disable default markers
        }}  />} */}
        {hasPath && (<>
            <Polyline
                path={routePathPastCurrent}
                options={{

                    icons: [
                        {
                            icon: dashIconPastCurrent,
                            offset: "0%",
                            repeat: "20px", // Distance between dots
                        },
                    ],
                }}
            />

            <Polyline
                path={routePathFuture}
                options={{

                    icons: [
                        {
                            icon: dashIconFuture,
                            offset: "0%",
                            repeat: "20px", // Distance between dots
                        },
                    ],
                }}
            />
            </>
        )}
    </>
    );
};

export default DottedRoute;
