import { createApi, fetchBaseQuery, skipToken } from '@reduxjs/toolkit/query/react'
import { RootState, } from "../app/store"
import { profileApi } from './profilesSlice'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'



export function getValuesByKeyString(obj: { [key: string]: any }, searchString: string): any[] {
    const result: any[] = [];

    // Iterate through object keys
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            // Check if key contains the search string
            if (key.includes(searchString)) {
                result.push(obj[key]);
            }
        }
    }
    return result;
}

export interface UserAndChat {//extends ChatUser {
    chatId: number
    //type: string
    id: number
    userName: string


}
export interface ChatUser {
    //iamId: string,
    id: number,
    userName?: string
}
export interface DirectChatUsers extends ChatUser {
    messageToId?: number
    sendInChat?: boolean
    sendEmail?: boolean
    message?: string
    messageChat?: string,
    messageEmail?: string,
    subject?: string
}
const GetMatchedQueryData: (api: any, toMatch: string) => any = (api, sub) => {
    const matchingSubQuerries = getValuesByKeyString(api.queries, sub)
    if (matchingSubQuerries.length === 0)
        return undefined
    if (matchingSubQuerries[0].status === 'fulfilled') {
        return matchingSubQuerries[0].data
    }
    return undefined
}

export const qryFuncWithSubHeader = fetchBaseQuery({
    prepareHeaders: (headers, { getState }) => {
        // console.log('prepareHeaders called');
        const state: any = getState();
        const userAttributesState = state.userattributes;
        if (userAttributesState.userAttributes?.sub) {
            const userName =  userAttributesState.userAttributes?.userName ?? userAttributesState?.HUBSignInEventData?.username ?? null
            const iamId =  userAttributesState.userAttributes?.sub ?? userAttributesState?.HUBSignInEventData?.setSignInDetails?.userId ?? null
            headers.set('name', userName);
            headers.set('sub', iamId)//userAttributesState.userAttributes.sub);
            const loggedinProfile = GetMatchedQueryData(state.profileApi, 'getLoggedInUserProfile')
            if (loggedinProfile) {
                headers.set('id', loggedinProfile.id);
            }
        } else {
            // //const guestProfileData = state.profileApi.queries["getLoggedInUserProfile({\"email\":null,\"iamId\":null,\"isFederated\":null,\"nickname\":null})"].data
            // //state.profileApi.queries["getLoggedInUserProfile({\"email\":null,\"iamId\":null,\"isFederated\":null,\"nickname\":null})"].data.lng
            // headers.set('sub', 'unauthenticated');
            headers.set('sub', 'unauthenticated');  //set in the endpoint also
        }
        return headers;
    },
})
export const chatApi = createApi({
    reducerPath: 'chatApi',
    baseQuery: qryFuncWithSubHeader,
    tagTypes: ['CHAT', 'Ready', 'ChatUsers', 'MessageCount', 'AnalyticsGroupChat', 'UpdateChatURL'],
    endpoints: (builder) => ({
        //gest profile  by iam_is inserts if no matching profile found        
        getChatUser: builder.query<any, DirectChatUsers | typeof skipToken>({
            query: (directChatUsers) => {
                if (directChatUsers === skipToken)
                    return ""
                const encodedUserName = encodeURIComponent(directChatUsers.userName ?? '');
                const url = `/api/chat/?username=${encodedUserName}&id=${directChatUsers.id}${directChatUsers.messageToId ? `&messageTo=${directChatUsers.messageToId}` : ""}`
                return url
            },
            providesTags: (result, response, arg) => {
                //return [{ type: 'CHAT' as const, id: arg === skipToken? undefined: arg.iamId }]
                return ['CHAT']
            }
        }),
        getMessaging: builder.query<any, number>({
            query: (id) => ({
                url: `/api/chat/Messaging/?id=${id}`,
                method: 'GET',
            }),
            providesTags: (result, response, updated) => {
                return ['Ready']//{ type: 'Ready', id: updated }]
            }
        }),
        getMessageCount: builder.query<any, number>({
            query: (id) => ({
                url: `/api/chat/MessageCount/?id=${id}`,
                method: 'GET',
            }),
            providesTags: (result, response, updated) => {
                return [{ type: 'MessageCount' }]
            }
        }),
        setChatUser: builder.mutation<any, DirectChatUsers>({
            query: (directChatUsers) => {
                const sentInChatParms = `${directChatUsers.sendEmail?`sendEmail=${directChatUsers.sendEmail}`:''}${directChatUsers.sendInChat?`&sendInChat=${directChatUsers.sendInChat}${directChatUsers.subject?`&subject=${directChatUsers.subject}`:''}${directChatUsers.messageChat?`&messageChat=${encodeURIComponent(directChatUsers.messageChat)}`:''}${directChatUsers.messageEmail?`&messageEmail=${encodeURIComponent(directChatUsers.messageEmail)}`:''}${directChatUsers.message?`&message=${directChatUsers.message}`:''}`:''}`           
                return {                
                url: `/api/chat${sentInChatParms?`?${sentInChatParms}`:''}`,
                method: 'PUT',
                body: [directChatUsers.id, ...directChatUsers.messageToId || directChatUsers.messageToId=== 0? [directChatUsers.messageToId] : []],//directChatUsers,
            }},
            invalidatesTags: (result, response, idsArg) => ['Ready']
        }),
        setNamedChatManyUsers: builder.mutation<any, { chatName: string, ids: number[] }>({
            query: ({ chatName, ids }) => ({
                url: `/api/chat/?chatName=${chatName}`,
                method: 'PUT',
                body: ids,//directChatUsers,
            }),
            invalidatesTags: (result, response, idsArg) => ['Ready']
        }),
        createOrJoinAnalyticsGroupChat: builder.mutation<any, { analyticsProfileId: number, initiatorId: number,  ids: number[], col: string, value: string }>({
            query: ({analyticsProfileId, initiatorId,  ids, col, value }) => {  //using header for authorization
                const encodedValue = encodeURIComponent(value ?? '');
                return {
                    url: `/api/chat/createOrJoinAnalyticsGroupChat/?analyticsProfileId=${analyticsProfileId}&initiatorId=${initiatorId}&col=${col}&value=${encodedValue}`,
                    method: 'PUT',
                    body: ids,// initiator id must be ids[0]
                }
            },
            invalidatesTags: (result, response, idsArg) => ['Ready']//'MessageCount',
        }),
        createOrJoinGroupChat: builder.mutation<any, { loggedInId: number, ids: number[], channelName: string, channelType: string }>({
            query: ({ loggedInId, ids, channelName, channelType }) => {
                const encodedValue = encodeURIComponent(channelName ?? '');
                return {
                    url: `/api/chat/createOrJoinGroupChat/?channelName=${encodedValue}&channelType=${channelType}`,
                    method: 'PUT',
                    body: ids,//
                }
            },
            invalidatesTags: (result, response, idsArg) => ['Ready']//'MessageCount',
        }),

        createOrJoinOpenChat: builder.mutation<any, { ids: number[],  channelUrl: string, channelName: string, channelType: string }>({
            query: ({ ids, channelUrl, channelName, channelType }) => {
                const encodedValue = encodeURIComponent(channelName ?? '');
                return {
                    url: `/api/chat/createOrJoinAnalyticsOpenChat/?channelName=${encodedValue}&channelUrl=${channelUrl}${channelType?`&channelType=${channelType}`:''}`,
                    method: 'PUT',
                    body: ids,//
                }
            },
            invalidatesTags: (result, response, idsArg) => ['Ready']//'MessageCount',
        }),

        // patchChatUser: builder.mutation<any, { iamId: string, type: string, stepsCompleted: number, surveyDTO: any }>({
        //     query: ({ iamId, type, stepsCompleted, surveyDTO: survey2Update }) => ({
        //         url: `/api/surveys?iamId=${iamId}&type=${type}&stepsCompleted=${stepsCompleted}`,
        //         method: 'PATCH',
        //         body: survey2Update,
        //     }),
        //     invalidatesTags: (result, response, updated) => [{
        //         type: 'Chat',
        //         id: updated.iamId
        //     }]
        // }),

        // updateChatUser: builder.mutation<any, { iamId: string, type: string, surveyDTO: any }>({
        //     query: ({ iamId, type, surveyDTO: survey2Update }) => ({
        //         url: `/api/surveys?iamId=${iamId}&type=${type}`,
        //         method: 'PUT',
        //         body: survey2Update,
        //     }),
        //     invalidatesTags: (result, response, updated) => [{
        //         type: 'Chat',
        //         id: updated.iamId
        //     }]
        // }),

        deleteChat: builder.mutation<void, UserAndChat>({
            query: (arg) => {
                //console.log('Deleting chat', arg)
                return ({
                    url: `/api/chat/?chatId=${arg.chatId}&id=${arg.id}&name=${arg.userName}`,
                    method: 'DELETE',
                })
            },
            //invalidatesTags: (result, response, updated) => [{ type: 'Profile', id: updated }]
        }),

        // deleteUser: builder.mutation<void, number>({
        //     query: (id) => {
        //         //console.log('Deleting chat', arg)
        //         return ({
        //             url: `/api/chat/deleteuser/?id=${id}`,
        //             method: 'DELETE',
        //         })
        //     },
        //     //invalidatesTags: (result, response, updated) => [{ type: 'Profile', id: updated }]
        //}),
    }),
})


interface UserState { count: number }
const initialState: UserState = { count: 0 };
export const chatCount = createSlice({
    name: 'chatCount',
    initialState,
    reducers: {
        setChatCount(state, action: PayloadAction<number>) {
            state.count = action.payload;
        },
    },
});
export const { setChatCount } = chatCount.actions;
export default chatCount.reducer;


export const { useGetMessagingQuery, useGetMessageCountQuery, useSetChatUserMutation, useSetNamedChatManyUsersMutation, useGetChatUserQuery, useDeleteChatMutation, useCreateOrJoinAnalyticsGroupChatMutation, useCreateOrJoinGroupChatMutation, useCreateOrJoinOpenChatMutation } = chatApi
