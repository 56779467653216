import React, { Children, useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
//import AdbIcon from '@mui/icons-material/Adb';
//import { LoadingButton } from '@mui/lab';
import { HeaderContent } from '../../app/sharedcomponents';
//import resourceData from './resources/ResourceData'
import { useNavigate, useLocation } from 'react-router-dom';
import { showProfile, showDeleteAccount } from "../../features/dialogSlice";
import { useSelector, useDispatch/*, shallowEqual*/ } from 'react-redux';
import Badge from '@mui/material/Badge';
import { signOut/*, deleteUser*/ } from 'aws-amplify/auth';
//import { getUserAttributes } from '../features/userAttributesSlice'
import { ConfirmDeleteProfile } from '../iam/DeleteProfile';
import { setPublicPinClicked } from '../../features/dataProcessingSlice';

import { useAuthProfile } from "../../app/hooks"
//import { useMultiChatLogic, MultiChatSocket, MultiChatWindow } from "react-chat-engine-advanced";
import { chatApi, useGetChatUserQuery, useGetMessageCountQuery } from '../../features/ChatSlice';
import { skipToken } from '@reduxjs/toolkit/query';
//import { useState, useEffect } from 'react';
import { profileApi } from '../../features/profilesSlice';
import { showCareCenters, showWarriors } from '../../features/filterSlice';
import { RightSideUIContext } from '../../App';
import { condition } from '../..';

const pages2show = [{ name: 'Map', href: '/' }, { name: "Messages", href: "/Messages" }, { name: "Resources", href: "/Resources" },{ name: "Blog", href: "https://www.polygonhealth.com/blog" }, { name: "Contact", href: "/Contact" }] //...linkData.map((row: any) => ({ name: row.name, href: '' }))]

const settingsAuthenticated = ['Map', 'Messages', 'Resources', 'Blog', 'Contact'];
const settingsUnAuthenticated = ['Login'];

// export const ChatsPageLoader = () => {
//     //const [projectId, setProjectId] = useState('');
//     //const [username, setUsername] = useState('');
//     //const [secret, setSecret] = useState('');

//     //const { authStatus, profile, isLoading: isAuthProfileLoading } = useAuthProfile()
//     const { authStatus, profile, isLoading: isAuthProfileLoading, isSuccess: isAuthProfileSuccess, isError: isAuthProfileError, error: authProfileError, userAttributesStateStatus } = useAuthProfile()
//     const aimIdsArg = (isAuthProfileSuccess && authStatus === 'authenticated' && profile.iam_id) ? [profile.iam_id] : skipToken

//     //const { data: chatUserData, isLoading, isSuccess, isError, error } = useGetChatUserQuery(aimIdsArg);

//     // useEffect(() => {
//     //     if (chatUserData != undefined) {
//     //         setProjectId(chatUserData.projectId)
//     //         setUsername(chatUserData.userCreationResults.username)
//     //         setSecret(chatUserData.userCreationResults.secret1)
//     //     } 
//     // }, [chatUserData]);

//     // if (isSuccess && authStatus === 'authenticated' && chatUserData?.projectId) {
//     //     const projectId = chatUserData.projectId
//     //     const username = chatUserData.userCreationResults.username
//     //     const secret = chatUserData.userCreationResults.secret1
//     //     return <ResponsiveAppBarConnect authStatus={authStatus} projectId={projectId} username={username} secret={secret} />;
//     // }
//     return <ResponsiveAppBar authStatus={authStatus} isSuccess={isAuthProfileSuccess} isError={isAuthProfileError} />;
// };
//getMessageCount
const RenderBadgeWrapper: React.FC<{ id: number | undefined, badgeIt: boolean, children?: React.ReactNode }> = ({ id, badgeIt, children }) => {

    return (<>{children}</>)
}

const RenderMenuTextBadge: (wrappedNode: React.ReactNode, badgeIt: string, index?: number) => React.ReactNode = (wrappedNode, badgeIt) =>
    <React.Fragment>
        {parseInt(badgeIt) > 0 ?
            <Badge showZero={false} badgeContent={badgeIt} color="error" overlap="circular">
                {wrappedNode}
            </Badge> :
            <> {wrappedNode}</>
        }
    </React.Fragment>




export const NavBar1: React.FC = () => {
    const { authStatus, profile, isLoading: isAuthProfileLoading, isSuccess: isAuthProfileSuccess, isError: isAuthProfileError, error: authProfileError, userAttributesStateStatus, messagingReady } = useAuthProfile()
    //const { sendBirdReady } = useContext(RightSideUIContext);
    const loggedInId = (messagingReady && isAuthProfileSuccess && authStatus === 'authenticated' && profile.id) ? profile.id : skipToken
    const { data: messageCountData, isUninitialized: isUninitializedMessageCount, isSuccess: isSuccessMessageCount, isLoading: isLoadingMessageCount, isError: isErrorMessageCount, error: errorMessageCount } =
        useGetMessageCountQuery(loggedInId, { pollingInterval: (process.env.NODE_ENV || 'development') === 'development' ? 60000 : 5000, skipPollingIfUnfocused: true, })


    const badgeMessageCount: string = isUninitializedMessageCount ? "!" : (isSuccessMessageCount ? (messageCountData.error ? "?" : messageCountData.unread_count.toString()) : (isErrorMessageCount ? "?" : "..."))
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const handleSignOut = async () => {
        try {
            dispatch(setPublicPinClicked(false))
            //await dispatch(profileApi.util.resetApiState());
            dispatch(showCareCenters(false));
            dispatch(showWarriors(false));
            await signOut();
            // dispatch(chatApi.util.invalidateTags(['Ready']))
            // //dispatch(signout());             
            // //navigate("/");
            // window.location.replace('/map');//window.location.assign('/'); // Navigate to '/'
            // window.location.reload();

        } catch (error) {
            // add error handling here
            console.log(error);
        }
    };
    const showDeleteAccountState = useSelector((state: any) => state.dialog.showDeleteAccount);
    // const userAttributesState = useSelector((state: any) => state.userattributes, (prev, next) => {
    //     if (!next.status.isSuccess && !next.status.isError) return true
    //     if (!prev.userAttributes) return false
    //     return prev.userAttributes.sub === next.userAttributes.sub
    // });

    async function handleDeleteAccount() {
        try {
            dispatch(showDeleteAccount(true))
            //setErrorVisible('hidden');
        } catch (error) {
            // add error handling here
            console.log(error);
        }
    };
    const location = useLocation();

    async function handleProfile() {
        try {
            dispatch(showProfile({ isForced: true }))
            // const currentPath = location.pathname;
            if (location.pathname > "/") {
                navigate("/")
            }
            setAnchorElUser(null);
        } catch (error) {
            // add error handling here
            console.log(error);
        }
    };

    //async Handle

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        if (isAuthProfileSuccess) {
            setAnchorElNav(event.currentTarget);
        }
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        if (isAuthProfileSuccess) {
            setAnchorElUser(event.currentTarget);
        }
    };

    const handleNavMenu: (menuOption: string) => void = (menuOption) => {
        if (isAuthProfileSuccess) {
            switch (menuOption) {
                case 'Map':
                    navigate("/")
                    break;
                default:
                    const route = menuOption.replace(/\s/g, '-')
                    navigate(`/${route}`)
                    break;
            }
            setAnchorElNav(null);
        }
    }
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleUserMenu = (menuOption: string) => {
        switch (menuOption) {
            case 'Map':
                navigate("/")
                break;
            case 'Messages':
                navigate("/Messages")
                break;
            case 'Resources':
                navigate("/Resources")
                break;
            case 'Blog':
                window.open("https://www.polygonhealth.com/blog", '_blank');
                break;
            case 'Contact':
                navigate("/Contact")
                break;
            case 'Delete Account':
                handleDeleteAccount()
                break;
            case 'Sign Out':
                handleSignOut()
                break;
            case 'Profile':
                handleProfile()
                break;
            //setAnchorElNav(null);
        }
        setAnchorElUser(null);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };



    //{authStatus === 'unauthenticated' && (<Button sx={{ backgroundColor: '#656EFF', color: 'white', fontSize: '12px' }} href="/map/Sign-In/">Sign in</Button>)}
    //const { /*user,*/ authStatus } = useAuthenticator((context) => [context.user])
    const pages = isAuthProfileSuccess ? pages2show.filter( (link, index) => link.name !== 'Blog' || condition === 'Multiple Myeloma') : []

    const settings = isAuthProfileSuccess ? authStatus === 'authenticated' ? settingsAuthenticated : authStatus === 'unauthenticated' ? settingsUnAuthenticated : [] : []//configuring status
    return (
        <AppBar position="sticky" elevation={0} sx={{ bgcolor: 'white', borderBottom:1, borderColor:'#E0E0E0' }} >
            <Container maxWidth={false}>
                <Toolbar disableGutters>
                    <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon style={{ color: 'black' }} />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            onClick={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.filter( (link, index) => link.name !== 'Blog' || condition === 'Multiple Myeloma').map((link, index) => (
                                <MenuItem key={index} onClick={() => handleUserMenu(link.name)}>
                                    {RenderMenuTextBadge(<Typography textAlign="center">{link.name} </Typography>, link.name === "Messages" ? badgeMessageCount : '')}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>

                    <HeaderContent />

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((link, index) => (
                            <React.Fragment key={index ?? 0}>{RenderMenuTextBadge(
                                <Button
                                    key={index}
                                    onClick={() => handleUserMenu(link.name)}
                                    sx={{
                                        color: (location.pathname.replace('map', '').replace('/', '') === link.name.replace('Map', '')) ? '#656EFF' : '#777777',
                                        fontWeight: 'bold',
                                        borderBottom: (location.pathname.replace('map', '').replace('/', '') === link.name.replace('Map', '')) ? 4 : 0,
                                        borderRadius: 0,
                                    }}
                                >
                                    {link.name}
                                </Button>, link.name === "Messages" ? badgeMessageCount : '')}</React.Fragment>
                        ))}
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        {authStatus === 'unauthenticated' && (<Button id="navbar_signin" sx={{ backgroundColor: '#656EFF', color: 'white', fontSize: '12px', borderRadius:8 }}
                            onClick={
                                () => {
                                    navigate("/Sign-in")
                                }
                            } >Sign in</Button>)}
                        {authStatus === 'authenticated' && (<>
                            <Tooltip title={`Account settings for ${profile?.name_public}`}>
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    {RenderMenuTextBadge(<Avatar sx={{ bgcolor: '#656EFF' }} />, badgeMessageCount)}
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                                onClick={handleCloseNavMenu}
                            >
                                {settings.map((setting, index) => (

                                    (setting == 'Messages')
                                        ?
                                        <MenuItem key={index} onClick={() => handleUserMenu(setting)} sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' } }}>
                                            {RenderMenuTextBadge(<Typography textAlign="center">{setting}</Typography>, badgeMessageCount)}
                                        </MenuItem>
                                        :
                                        <MenuItem key={index} onClick={() => handleUserMenu(setting)} sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' } }}>
                                            <Typography textAlign="center">{setting}</Typography>
                                        </MenuItem>
                                ))}
                                <MenuItem key={'delete'} onClick={() => handleUserMenu('Sign Out')} >
                                    <Typography textAlign="center">{'Sign Out'}</Typography>
                                </MenuItem>
                                <MenuItem key={'signout'} onClick={() => handleUserMenu('Delete Account')} >
                                    <Typography textAlign="center">{'Delete Account'}</Typography>
                                </MenuItem>
                            </Menu>
                        </>)}
                    </Box>
                </Toolbar>
                
            </Container>
        </AppBar >
    );
}
//{showDeleteAccountState && <ConfirmDeleteProfile handleSignOut={handleSignOut} />}
//export default ChatsPageLoader;