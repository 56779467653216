import React, { /*useEffect, useState,*/ useMemo } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  DirectionsRenderer,
  GoogleMap, //Marker, MarkerF, 
  useJsApiLoader
} from '@react-google-maps/api';
//import { UserSubmitDataMarker } from '../UserSubmitDataMarker';
//import { MMMarker } from '../markers/MMMarkers';
import { UserMarker } from '../markers/UserMarker';
import { /*Paper, Stack, */Box } from "@mui/material";
//import { loadCareCenterData, remove_carecenters } from '../../features/CareCenterDataSlice';
import { /*search, getSearchByTerm, */getSearchByCoordinates, SearchState, setCenter, setZoom } from "../../features/searchSlice";
//import { useGetProfilesForPointQuery, useDeleteProfileMutation, useAddProfileMutation, useUpdateProfileMutation, isFetchBaseQueryError, isErrorWithMessage } from '../../features/profilesSlice';
//import { useAuthenticator } from "@aws-amplify/ui-react";
//import { skipToken } from "@reduxjs/toolkit/query";
//import { CenterMapHome } from "../map/CenterMapHome";
import { CircularProgressCentered } from '../../app/sharedcomponents';
import { InstitutionMarker, InstitutionMarkers, ShowMarkerType } from "../markers/InstitutionMarkers"
//import { useGetProfilesPinDataQuery } from "../../features/profilesSlice"
import { Warriors } from "../markers/MMMarkers"
import { SendbirdChatButton } from "../messaging/SendbirdChatButton"
//import { useBikeRoute } from './BikeRouteHook'
import DottedRoute from "./DottedRoute";
//function Warriors({ center }) {


// function CustomMarker() {
//   return (

//     <MMMarker key={'goodness'} markerType="housing"
//       lat={'34.795610'} lng={'-92.404030'}
//       href={'https://goodnessvillage.org/'} address={'11610 Pleasant Ridge Road, Little Rock, AR 72223'} name={'Goodness Village'} id={'goodness'} phone={'(501) 530-0095'} />

//   );
// }

export const mapLibs: any[] = ['maps', 'places']

export const Map: React.FC<{ authStatus: any, profile: any }> = ({ authStatus, profile }) => {

  const { isLoaded } = useJsApiLoader({ id: 'google-map-script', googleMapsApiKey: "AIzaSyDvqapzpG_NMGMUv9Nj9IJnLNasbt_XzR8", libraries: mapLibs})// ['maps', 'places'] });

  // const searchState = useSelector(state => state.search, (prev, next) => {
  //   if (!next.status.isSuccess) return true
  //   if (!prev.center) return false
  //   const ret = false//prev.formattedSearchTerm === next.formattedSearchTerm &&  prev.center?.lng === next.center?.lng&& prev.center?.lat === next.center?.lat//&& ( prev.showProfileDialog === next.showProfileDialog  )
  //   return ret
  // });
  //const searchState = useSelector(state => state.search);
  //const center = searchState.center
  const center = useSelector((state: any) => state.search.center);
  if (!isLoaded) {
    return (<><CircularProgressCentered /></>)
  }
  //console.log(`About to render MapLoaded with ${center.lat} ${center.lng}`)
  //return (
  //<MapContainer 
  //return (<MemoizedMapLoaded center={{ lat: center.lat, lng: center.lng }} authStatus={authStatus} />
  return (<MapLoaded center={{ lat: center.lat, lng: center.lng }} authStatus={authStatus} profile={profile} />

  )
}




export const MapLoaded: React.FC<{ center: any, authStatus: any, profile: any }> = ({ center, authStatus, profile }) => {
  let root = document.getElementById('root');
  root?.style.setProperty('position', 'static', 'important');
  root?.style.setProperty('width', '100%', 'important')

  // const { isLoaded } = useJsApiLoader({
  //    id: 'google-map-script',
  //    googleMapsApiKey: "AIzaSyDvqapzpG_NMGMUv9Nj9IJnLNasbt_XzR8",
  //  });
  //const [ showMarkers, setShowMarkers] = useState( {})
  const [map, setMap] = React.useState<google.maps.Map | null>(null)

  const searchState: SearchState = useSelector((state: any) => state.search);

  //const isLoaded = true
  const dispatch = useDispatch();
  //const filter = useSelector(useMemo(() => (state) => state.filter.showCareCenters, [],))
  const filter = useSelector(useMemo(() => (state: any) => state.filter, []), shallowEqual)

  //console.log(`Rendering MapLoaded ${center ? center.lat : "none"}`)//,  ${center ? center.lng : "none"} Error: ${searchState.error ?? "none"} Error: ${searchState.isError ?? "none"}  Loading: ${searchState.isLoading ?? "none"} Success:${searchState.isSuccess ?? "none"} `)

  const containerStyle = {
    width: '100%',
    height: '100%'
  };

  const onLoad = React.useCallback(function callback(map: google.maps.Map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!    
    //dispatch(loadData({ type: "carecenters", center: searchState.center }));
    setMap(map)
    //const bounds = new window.google.maps.LatLngBounds(center);

  }, [])

  /*const onDragEnd = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!    
    //dispatch(loadData({ type: "carecenters", center: searchState.center }));
    //setMap(map)
    //const bounds = new window.google.maps.LatLngBounds(center);
    console.log(map)
    //dispatch(setCenter({ lat: event.latLng.lat(), lng: event.latLng.lng() }))
  }, [])*/

  function onDragEnd() {
    //setZoom(map.getZoom())
    if (map) {
      const center = map.getCenter();
      if (center) {
        const centerLat = center.lat();
        const centerLng = center.lng();
        dispatch(setCenter({ lat: centerLat, lng: centerLng }))
      }
    }
  }

  function onZoomChanged() {
    if (map) {
      const zoom = map.getZoom();
      if (zoom) {
      dispatch(setZoom(zoom))
      }
    }
  }


  // const onClick = React.useCallback((event) => {
  //   // Extract the clicked coordinates
  //   const clicked = { lat: event.latLng.lat(), lng: event.latLng.lng() }
  //   // Recenter the map to the clicked coordinates
  //   dispatch(getSearchByCoordinates(clicked));
  // }, [dispatch]);


  //const { directions, bikeStops, routePath } = useBikeRoute(true)

  if (!center) {
    return (<>'no Center'</>)//null
  }

  //console.log(profile)
  //{(filter.showCareCenters) && <CustomMarker />}
  return (
    <Box
      component="div"
      sx={{
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        zIndex: 0,
      }}
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={searchState.zoom}
        onLoad={onLoad}
        onDragEnd={onDragEnd}
        onZoomChanged={onZoomChanged}
        //onClick={onClick}
        options={{
          streetViewControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
          zoomControl: true,
          minZoom: 3,
          zoomControlOptions: {
            position: 8,
          },
          mapId: 'b5fcf45ccbb2b48',
          gestureHandling: "greedy"
        }}
      >
        {/* {(false && !filter.showCareCenters && !filter.showWarriors) && <UserSubmitDataMarker />} */}
        {(filter.showCareCenters) && <InstitutionMarkers zoom={searchState.zoom} />}
        {(filter.showWarriors) && <Warriors zoom={searchState.zoom} />}
        {(authStatus === 'authenticated' && profile.is_completed) && <UserMarker zoom={searchState.zoom} />}


        <DottedRoute zoom={searchState.zoom} />      
      </GoogleMap>

    </Box>)

}
// {( false && !filter.showCareCenters && !filter.showWarriors) && <UserSubmitDataMarker />}
// {(filter.showCareCenters) && <InstitutionMarkers zoom={searchState.zoom}/>}
// {( filter.showWarriors) && <Warriors center={center} zoom={searchState.zoom}/>}
const MemoizedMapLoaded = React.memo(MapLoaded, shallowEqual);
//export const MapLoadedMemoized = React.memo(MapLoaded)
//<MarkerF position={center} animation={window.google.maps.Animation.BOUNCE} />

