import React, { useCallback, useState } from "react";
import { GoogleMap, Marker, DirectionsRenderer, useLoadScript } from "@react-google-maps/api";
import bikeStops, { BikeStop } from "../resources/BikeStops";

interface Point {
  lat: number;
  lng: number;
  title: string;
}

const mapContainerStyle = {
  width: "100%",
  height: "500px",
};

// const center = { lat: 40.748817, lng: -73.985428 }; // Default center (e.g., NYC)

// const BikeRouteMapWithMultiplePoints: React.FC = () => {
//   const { isLoaded } = useLoadScript({
//     googleMapsApiKey: "YOUR_GOOGLE_MAPS_API_KEY", // Replace with your API key
//   });

//   const [directions, setDirections] = useState<google.maps.DirectionsResult | null>(null);

//   // Define multiple points
//   const points: Point[] = BikeStops.map( (bikeStop, index) =>  ({ lat: bikeStop.lat, lng: bikeStop.lng, title:`Wheeling for Healing Stop ${index}` }))
//   // [
//   //   { lat: 40.748817, lng: -73.985428, title: "Start Point" },
//   //   { lat: 40.74061, lng: -73.935242, title: "Mid Point" },
//   //   { lat: 40.73061, lng: -73.955242, title: "End Point" },
//   // ];

//   const calculateRoute = useCallback(() => {
//     if (points.length < 2) return;

//     const directionsService = new google.maps.DirectionsService();

//     // Prepare waypoints for intermediate points
//     const waypoints = points.slice(1, -1).map((point) => ({
//       location: { lat: point.lat, lng: point.lng },
//       stopover: true,
//     }));

//     directionsService.route(
//       {
//         origin: points[0],
//         destination: points[points.length - 1],
//         waypoints,
//         travelMode: google.maps.TravelMode.BICYCLING,
//       },
//       (result, status) => {
//         if (status === google.maps.DirectionsStatus.OK && result) {
//           setDirections(result);
//         } else {
//           console.error("Error fetching directions:", status);
//         }
//       }
//     );
//   }, [points]);

//   // Trigger route calculation when the component mounts
//   React.useEffect(() => {
//     calculateRoute();
//   }, [calculateRoute]);

//   if (!isLoaded) return <div>Loading...</div>;

//   return (
//     <GoogleMap
//       mapContainerStyle={mapContainerStyle}
//       center={center}
//       zoom={14}
//     >
//       {points.map((point, index) => (
//         <Marker
//           key={index}
//           position={{ lat: point.lat, lng: point.lng }}
//           title={point.title}
//         />
//       ))}

//       {directions && <DirectionsRenderer directions={directions} />}
//     </GoogleMap>
//   );
// };

// export default BikeRouteMapWithMultiplePoints;


export interface useBikeRouteInterface {
  directions: google.maps.DirectionsResult | null,
  points: Point[],
  bikeStops: BikeStop[]
  routePathPastCurrent: google.maps.LatLngLiteral[]
  routePathFuture: google.maps.LatLngLiteral[]
  error: string | undefined
}


export const useBikeRoute = (calculate: boolean): useBikeRouteInterface => {
  const [points, setPoints] = useState<Point[]>([])
  const [directions, setDirections] = useState<google.maps.DirectionsResult | null>(null);
  const [routePathPastCurrent, setRoutePathPastCurrent] = useState<google.maps.LatLngLiteral[]>([]);
  const [routePathFuture, setRoutePathFuture] = useState<google.maps.LatLngLiteral[]>([]);
  //  const [routePath, setRoutePath] =  useState<{pathPastCurrent: pathFuture:google.maps.LatLngLiteral[],  pathFuture:google.maps.LatLngLiteral[]}>({pathPastCurrent:[], pathFuture:[]});
  const [routePath, setRoutePath] =  useState<{pathFuture:google.maps.LatLngLiteral[], pathPastCurrent: google.maps.LatLngLiteral[]}>({pathFuture:[], pathPastCurrent: []});
  const [error, setError] = useState<string | undefined>()

  const calculateRoute = useCallback(() => {
    if (!calculate)
      return
    //const points: Point[] = bikeStops.map( (bikeStop, index) =>  ({ lat: bikeStop.lat, lng: bikeStop.lng, title:`Wheeling for Healing Stop ${index}` }))

    const pointsObject = bikeStops.reduce(
      (pointArrays, bikeStop, index) => {
        const point: Point = { lat: bikeStop.lat, lng: bikeStop.lng, title: `Wheeling for Healing Stop ${index}` }
        switch (bikeStop.type.status) {
          case 'C':
            pointArrays.pointsFuture.push(point);
            pointArrays.pointsPastCurrent.push(point)
            break
          case 'F':
            pointArrays.pointsFuture.push(point);
            break
          default:
            pointArrays.pointsPastCurrent.push(point)

        }
        return pointArrays
      },
      {pointsPastCurrent:[], pointsFuture:[]} as { pointsPastCurrent: Point[], pointsFuture: Point[] } // Initialize two empty arrays for passing and failing elements
    );

    const directionsService = new google.maps.DirectionsService();

    const getWwayPoints: (points: Point[], setFunc: (p: google.maps.LatLngLiteral[]) => void) => any = (points, setFunc) => {
      if (points.length < 2)
        return []
      const waypoints = points.slice(1, -1).map((point) => ({
        location: { lat: point.lat, lng: point.lng },
        stopover: true,
      }));
      directionsService.route(
        {
          origin: points[0],
          destination: points[points.length - 1],
          waypoints,
          travelMode: google.maps.TravelMode.BICYCLING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK && result) {
            setDirections(result);
            const path = result.routes[0].overview_path.map((point) => ({
              lat: point.lat(),
              lng: point.lng(),
            }));

            setFunc(path)
            setPoints( ( pts: Point[]) => [...pts, ...points])          
            //setPoints( points)
          } else {
            const error = `Error fetching directions: ${status}`
            console.error(error);
            setError(error)            
          }
        }
      );
    }
     getWwayPoints(pointsObject.pointsPastCurrent, setRoutePathPastCurrent)
     getWwayPoints(pointsObject.pointsFuture, setRoutePathFuture)

  }, [bikeStops]);

  React.useEffect(() => {
    calculateRoute();
  }, [calculateRoute]);


  return {
    routePathPastCurrent, routePathFuture, directions, points, bikeStops, error
  }

}



