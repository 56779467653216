import React, { useState, FC } from "react"
//import Modal from '@mui/material/Modal';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
//import Grid from '@mui/material/Grid';
//import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";

import { /*getDisclaimerCookie, */setDisclaimerCookie } from '../app/utils'
import { useMediaQuery, useTheme, Dialog, Slide, DialogActions, Link, DialogContent, ListItemText, List, ListItem, Container, Grid, TextField } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { PrivacyLink, TermsOfUseLink } from "../app/sharedcomponents";
import { showDataDeletionInstructions, showDisclaimer } from "../features/dialogSlice";
import { Box, Stack } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import page2PNG from '../assets/disclaimer_page2.png'
import page3PNG from '../assets/disclaimer_page3.png'
import page4PNG from '../assets/disclaimer_page4.png'
import page5PNG from '../assets/disclaimer_page5.png'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { ReactComponent as WelcomePC1 } from '../assets/welcome_pc_1.svg';
import { ReactComponent as WelcomePC2 } from '../assets/welcome_pc_2.svg';
import { ReactComponent as WelcomePC3 } from '../assets/welcome_pc_3.svg';
import { ReactComponent as WelcomePC4 } from '../assets/welcome_pc_4.svg';
import MobileStepper from '@mui/material/MobileStepper';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import { coonditionContext, Condition, capitalizeWords } from '../index'




import PlaceIcon from '@mui/icons-material/Place';
import EmailIcon from '@mui/icons-material/Email';
import emailjs from '@emailjs/browser';
import { useAuthProfile } from "../app/hooks"

export const Disclaimer: FC = () => {

  //const [UpdateProfile, { isLoading, isSuccess, isError, error, data: updatedProfile, reset }] = useUpdateProfileMutation()
  const [openDisclaimer, setOpenDisclaimer] = useState(true);
  const disclaimerState = useSelector((state: any) => state.disclaimer)
  const [page, setPage] = useState(1)
  const dispatch = useDispatch();
  //const loginState = useSelector(state => state.login);
  const navigate = useNavigate();

  async function handleAgreeDisclaimer(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault()
    dispatch(showDisclaimer(false));
    setDisclaimerCookie()
    setOpenDisclaimer(false)
  }

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  //console.log(isSmallScreen)
  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="right" ref={ref} {...props} />;
  });
  const {condition} = React.useContext<Condition>(coonditionContext);
  return (
    <Dialog
      open={openDisclaimer}
      sx={{maxWidth:'90%'}}
      PaperProps={{ sx: { borderRadius: '12px', padding: '12px', left:'5%',marginLeft:'auto', marginRight:'auto' } }}
    >

      <DialogContent>
        {page === 1 && 
          <Stack direction="column" gap={2}>
            <Typography id="modal-modal-title" variant="h6">
              Welcome to Patient Connect
            </Typography>
            <Stack gap={2} sx={{display:'flex', border:1, borderRadius:2, width:'100%', backgroundColor:'#F5F3FF', borderColor:'#F5F5F5',p:2}}>
              <Typography variant="body1" sx={{mb: 1, color:'#62648F', fontWeight:'bold'}}>
                How Does Patient Connect Help?
              </Typography>
              <Stack direction="row" spacing={1} sx={{display:'flex', alignItems:'top',}}>
                <Box sx={{width:'32px', height:'32px'}}>
                  <WelcomePC1/>
                </Box>
                <Stack direction="column" gap={0}>
                  <Typography variant="body2" sx={{fontWeight:'bold'}}>
                    Connect with patients similar to you
                  </Typography>
                  <Typography variant="body2">
                    Share your story, explore similar experiences, and chat with others.
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" spacing={1} sx={{display:'flex', alignItems:'top'}}>
                <Box sx={{width:'32px', height:'32px'}}>
                  <WelcomePC2/>
                </Box>
                <Stack direction="column" gap={0}>
                  <Typography variant="body2" sx={{fontWeight:'bold'}}>
                    Learn from real experiences
                  </Typography>
                  <Typography variant="body2">
                    Discover insights from patients and caregivers.
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" spacing={1} sx={{display:'flex', alignItems:'top'}}>
                <Box sx={{width:'32px', height:'32px'}}>
                  <WelcomePC4/>
                </Box>
                <Stack direction="column" gap={0}>
                  <Typography variant="body2" sx={{fontWeight:'bold'}}>
                    Locate care centers
                  </Typography>
                  <Typography variant="body2">
                    Find specialists, patient lodging, and more.
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" spacing={1} sx={{display:'flex', alignItems:'top'}}>
                <Box sx={{width:'32px', height:'32px'}}>
                  <IconInCircle/>
                </Box>
                <Stack direction="column" gap={0}>
                  <Typography variant="body2" sx={{fontWeight:'bold'}}>
                    Find local support groups
                  </Typography>
                  <Typography variant="body2">
                  Locate and connect with your local support group to enhance your network of support.
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" spacing={1} sx={{display:'flex', alignItems:'top'}}>
                <Box sx={{width:'32px', height:'32px'}}>
                  <WelcomePC3/>
                </Box>
                <Stack direction="column" gap={0}>
                  <Typography variant="body2" sx={{fontWeight:'bold'}}>
                    Explore resources
                  </Typography>
                  <Typography variant="body2">
                    Use Knowledge Connect to help you search for answers to your questions.
                  </Typography>
                </Stack>
              </Stack>
              <Typography variant="body2" sx={{fontWeight:'bold'}}>The Polygon Health team sincerely wishes Patient Connect can be of service to you</Typography>
            </Stack>
          </Stack>
        }
        {page === 2 && 
          <Stack direction="column" gap={1}>
            <Stack direction="row" sx={{display:'flex', justifyContent:'space-between'}}>
              <Typography variant="h6">
                Discover Patient Stories
              </Typography>
              <Button
                  disableElevation
                  variant="text"
                  onClick={() => {setPage(6)}}
                  sx={{color:'#656EFF'}}
                >
                Skip
              </Button>
            </Stack>
            <Card elevation={0} sx={{backgroundColor:'#F5F3FF', maxWidth:'400px', borderRadius:2}}>
              <CardMedia
                component="img"
                sx={{ maxHeight:'400px', objectFit:'contain' }}
                image={page2PNG}
              />
              <CardContent sx={{p:0, '&:last-child': { pb: 0 }}}>
                <ul style={{fontWeight:'500', fontSize:'13px'}}>
                  <li>Add your pin and share your story</li>
                  <li>Discover detailed patient stories</li>
                  <li>Connect with others by starting a conversation</li>
                </ul>
              </CardContent>
            </Card>
          </Stack>
        }
        {page === 3 && 
          <Stack direction="column" gap={2}>
            <Stack direction="row" sx={{display:'flex', justifyContent:'space-between'}}>
              <Typography variant="h6">
                Connect with Patients Similar to You
              </Typography>
              <Button
                    disableElevation
                    variant="text"
                    onClick={() => {setPage(6)}}
                    sx={{color:'#656EFF'}}
                  >
                  Skip
                </Button>
              </Stack>
            <Card elevation={0} sx={{backgroundColor:'#F5F3FF', maxWidth:'400px', borderRadius:2}}>
              <CardMedia
                component="img"
                sx={{ maxHeight:'400px', objectFit:'contain' }}
                image={page3PNG}
              />
              <CardContent sx={{p:0, '&:last-child': { pb: 0 }}}>
                <ul style={{fontWeight:'500', fontSize:'13px'}}>
                  <li>Review detailed summaries based on patient stories</li>
                  <li>See which members have similarities</li>
                  <li>Start a group chat</li>
                </ul>
              </CardContent>
            </Card>
          </Stack>
        }
        {page === 4 && 
          <Stack direction="column" gap={2}>
            <Stack direction="row" sx={{display:'flex', justifyContent:'space-between'}}>
              <Typography variant="h6">
                Explore Our Map
              </Typography>
              <Button
                  disableElevation
                  variant="text"
                  onClick={() => {setPage(6)}}
                  sx={{color:'#656EFF'}}
                >
                Skip
              </Button>
            </Stack>
            <Card elevation={0} sx={{backgroundColor:'#F5F3FF', maxWidth:'400px', borderRadius:2}}>
              <CardMedia
                component="img"
                sx={{ maxHeight:'400px', objectFit:'contain' }}
                image={page4PNG}
              />
              <CardContent sx={{p:0, '&:last-child': { pb: 0 }}}>
                <ul style={{fontWeight:'500', fontSize:'13px'}}>
                  <li>Filter the map to easily find what you're looking for</li>
                  <li>Learn about care centers that specialize in {condition.toLowerCase()}</li>
                  <li>Find patient lodging options to consider during treatment</li>
                </ul>
              </CardContent>
            </Card>
          </Stack>
        }
        {page === 5 &&
          <Stack direction="column" gap={2}>
            <Stack direction="row" sx={{display:'flex', justifyContent:'space-between'}}>
              <Typography variant="h6">
                Explore Resources
              </Typography>
              <Button
                  disableElevation
                  variant="text"
                  onClick={() => {setPage(6)}}
                  sx={{color:'#656EFF'}}
                >
                Skip
              </Button>
            </Stack>
            <Card elevation={0} sx={{backgroundColor:'#F5F3FF', maxWidth:'400px', borderRadius:2}}>
              <CardMedia
                component="img"
                sx={{ maxHeight:'400px', objectFit:'contain' }}
                image={page5PNG}
              />
              <CardContent sx={{p:0, '&:last-child': { pb: 0 }}}>
                <ul style={{fontWeight:'500', fontSize:'13px'}}>
                  <li>Find questions to ask and get answers</li>
                  <li>Find relevant links, summaries, and discussions</li>
                  <li>Share resources with your network</li>
                </ul>
              </CardContent>
            </Card>
          </Stack>
        }
        {page === 6 && 
          <Stack direction="column" gap={2}>
            <Typography id="modal-modal-title" variant="h6">
              Welcome to Patient Connect
            </Typography>
            <Typography id="description1">
              The Polygon Health team sincerely wishes that this platform can be of service to you.
            </Typography>
            <Typography id="description1">
              To continue, please read and accept our {TermsOfUseLink} and {PrivacyLink}
            </Typography>
          </Stack>
          
        }
      </DialogContent>
      {(page > 1 && page < 6) &&
      <MobileStepper
        variant="dots"
        steps={4}
        position="static"
        activeStep={page-2}
        sx={{ maxWidth: 400, flexGrow: 1, m:'auto' }}
        nextButton={null}
        backButton={null}
      />
      }
      <DialogActions sx={{display:'flex', justifyContent:'space-evenly', alignItems:'center'}}>
          {page === 1 &&
            <Button
              disableElevation
              variant="contained"
              onClick={() => {setPage(2)}}
              sx={{backgroundColor:'#656EFF', borderRadius:8}}
            >
              Continue
            </Button>
          }
          {(page > 1 && page < 6) &&
            <Stack direction="row" sx={{display:'flex', justifyContent:'space-between', width:'100%'}} gap={2}>
              <Button
                disableElevation
                variant="text"
                onClick={() => {setPage(page-1)}}
                sx={{color:'#777777'}}
              >
                Back
              </Button>
              <Button
                disableElevation
                variant="contained"
                onClick={() => {setPage(page+1)}}
                sx={{backgroundColor:'#656EFF', borderRadius:8}}
              >
                Continue
              </Button>
            </Stack>
          }
          {page === 6 && 
            <Stack direction="row" sx={{display:'flex', justifyContent:'space-between', width:'100%'}} gap={2}>
              <Button
                disableElevation
                variant="text"
                onClick={() => {setPage(page-1)}}
                sx={{color:'#777777'}}
              >
                Back
              </Button>
              <Button
                disableElevation
                variant="contained"
                onClick={handleAgreeDisclaimer}
                sx={{backgroundColor:'#656EFF', borderRadius:8}}
              >
                I Accept
              </Button>
            </Stack>
          }
      </DialogActions>
    </Dialog>
  );


}


export const DataDeletionInstructions: FC = () => {
  const [openDisclaimer, setOpenDisclaimer] = useState(true);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  //console.log(isSmallScreen)
  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="right" ref={ref} {...props} />;
  });
  const dispatch = useDispatch()
  const showDataDeletionInstructionsState: boolean = useSelector((state: any) => {
   return  state.dialog.showDataDeletionInstructions
  })
  if (!showDataDeletionInstructionsState) {
    return null
  }
  return (<Dialog
    onClose={() => dispatch(showDataDeletionInstructions(false))}
    sx={{
      width: isSmallScreen ? '100vw' : '50vw', // Set width to 100% on small screens, 90% on larger screens
      maxWidth: isSmallScreen ? 'none' : '1000px', // Remove maximum width on small screens, set to 400px on larger screens
      height: isSmallScreen ? '70vh' : undefined, // Set height to 100% on small screens, 70% on larger screens
      maxHeight: isSmallScreen ? 'none' : '900px', // Remove maximum height on small screens, set to 600px on larger screens
      //position: 'fixed', // Use fixed positioning
      //top: isSmallScreen ? '10vw' : '10vw', // Set bottom to 0 on small screens, 20px on larger screens
      //left: isSmallScreen ? '0' : '70vw', // Set right to 0 on small screens, 20px on larger screens
    }}
    open={showDataDeletionInstructionsState}
    TransitionComponent={Transition}

    PaperProps={{ sx: { width: '100%', height: '100%', borderRadius: '12px', padding: '12px' } }}
  >

    <DialogContent>
      <Typography id="modal-modal-title" variant="h6">
        Data Deletion Instructions
      </Typography>
      <Typography id="description" sx={{ mt: 2 }}>

      To delete your data in the Polygon Health Map application, follow these steps:

        <List sx={{ listStyleType: 'disc',pl: 4 , }}>
      <ListItem sx={{ display: 'list-item' }}>
        <ListItemText primary="Ensure you are logged in to the Polygon Health Map application." />
      </ListItem>
      <ListItem sx={{ display: 'list-item' }}>
        <ListItemText primary="From the Profile menu, select 'Delete Account'." />
      </ListItem>
      <ListItem sx={{ display: 'list-item' }}>
        <ListItemText primary="Enter 'delete' to confirm the deletion in the Confirm Account Deletion window." />
      </ListItem >
      <ListItem sx={{ display: 'list-item' }}>
        <ListItemText primary='Click on "DELETE ACCOUNT".' />
      </ListItem>
    </List>
      </Typography >

      <Typography id="links" sx={{ mt: 2 }}>
        <div>
          {PrivacyLink}
        </div>
        <div>
          {TermsOfUseLink}
        </div>
      </Typography>
    </DialogContent>


  </Dialog>)
}



export const Contact: FC = () => {
  let root = document.getElementById('root');
  root?.style.setProperty('position','static','important');
  root?.style.setProperty('width','100%','important')

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState<string|null>(null);
  const { authStatus, profile, isLoading: isAuthProfileLoading, isSuccess, isError, error, userAttributesStateStatus } = useAuthProfile()
  const sendEmail = (e: any) => {
    e.persist();
    e.preventDefault();
    setIsSubmitting(true);
    emailjs
      .sendForm(
        'service_300ei6f',
        'template_psc8p6a',
        e.target,
        'IREfcB-Wlqf2SmkvK'
      )
      .then(
        (result) => {
          setStateMessage('Message sent!');
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // hide message after 5 seconds
        },
        (error) => {
          setStateMessage('Something went wrong, please try again later');
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // hide message after 5 seconds
        }
      );
    
    // Clears the form after sending the email
    e.target.reset();
  };
  

  const {condition} = React.useContext(coonditionContext);

  return (
    <Container>
      <Stack
        sx={{
          pt:8,
          pb:8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          justifyItems: 'center',
        }}
      >
        <Typography sx={{fontWeight:'bold'}}component="h1" variant="h3">
          Contact Us
        </Typography>
        <Grid container spacing={0} sx={{mt:4,border:1, borderRadius: 4, borderColor:'white', overflow:'hidden'}}>
          <Grid item md={4} 
            sx={{
              backgroundColor:'#656EFF', 
              color:'white', 
              pt:{lg:8,md:8,sm:4,xs:2}, 
              pl:{lg:4,md:4,sm:4,xs:2}, 
              pr:{lg:4,md:4,sm:4,xs:2}, 
              pb:{lg:8,md:8,sm:4,xs:2}, 
              width:'100%'
            }}>
            <Typography variant="h5" sx={{fontWeight:'bold'}}> 
              Contact Information
            </Typography>
            <Stack gap={1} sx={{pt:1,display: 'flex',flexDirection: 'row',alignItems: 'center' }}>
              <EmailIcon />
              <Link href={`mailto:contact@polygonhealth.com`} underline="always" color="#FFFFFF">
                {'contact@polygonhealth.com'}
              </Link>
            </Stack>
            <Stack gap={1} sx={{pt:1,display: 'flex',flexDirection: 'row',alignItems: 'center' }}>
              <PlaceIcon />
              <Typography variant="body1" sx={{color:'white'}}>
                One Broadway, 14th Floor, Cambridge MA 02142
              </Typography>
            </Stack>
            <Stack sx={{pt:1}} gap={1}>
              <Link href={`https://www.polygonhealth.com/privacy-policy`} underline="always" color="#FFFFFF" target="_blank">
                {'Privacy Policy'}
              </Link>
              <Link href={`https://www.polygonhealth.com/termsofuse`} underline="always" color="#FFFFFF" target="_blank">
                {'Terms of use'}
              </Link>
            </Stack>
          </Grid>
          <Grid item md={8} sx={{
            backgroundColor:'#F8F8F8',
            width:'100%',
            pt:{lg:8,md:8,sm:4,xs:2}, 
            pl:{lg:8,md:8,sm:4,xs:2}, 
            pr:{lg:8,md:8,sm:4,xs:2}, 
            pb:{lg:8,md:8,sm:4,xs:2}, 
          }}><div>
            <Typography variant="h5" sx={{fontWeight:'bold'}}>
              Send us your feedback
            </Typography>
            <Typography variant="body2" sx={{fontWeight:'bold'}}>
              We are continuing to improve the {condition}  Patient Connect and would love to hear your feedback and suggestions to help us create a better user experience!
            </Typography>
            <form onSubmit={sendEmail}>
              <Stack sx={{pt:3,display: 'flex',flexDirection: 'column',alignItems: 'left'}}>
                {(authStatus === 'authenticated' && !isAuthProfileLoading && profile) 
                  ? <TextField sx={{display:'none'}} id="outlined-basic" label="Outlined" variant="outlined" type="text" name="user_email" value={profile.name_public}/>
                  : <TextField sx={{display:'none'}} id="outlined-basic" label="Outlined" variant="outlined" type="text" name="user_email" value="Anonymous"/>
                }
                <TextField
                  name="message"
                  id="outlined-multiline-static"
                  label="Write your feedback here"
                  multiline
                  rows={4}
                  sx={{width:'100%', mt:1,mb:1, backgroundColor:'white'}}
                />
                <Button type="submit" variant="contained" disableElevation disabled={isSubmitting} sx={{backgroundColor:'#656EFF'}}>
                  Send Feedback
                </Button>
                {stateMessage && <p>{stateMessage}</p>}
              </Stack>
            </form>
            </div>
          </Grid>
        </Grid>
        </Stack>
      </Container>
  )
}

const IconInCircle = () => {
  return (
    <Box
      sx={{
        width: 29,
        height: 29,
        borderRadius: "50%",
        backgroundColor: "#E1DEF8",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& .MuiSvgIcon-root": {
          fontSize: 17,
          color: "#62648F",
        },
      }}
    >
      <Diversity3Icon />
    </Box>
  );
};